<template>
  <section class="login-wrap">
    <div class="hidden-box">
      <div class="inner-box">
        <div class="login-tit">
          <h3 class="big-tit">비밀번호 찾기</h3>
        </div>
        <div class="pb-2">
          <v-text-field
            v-model="emailId"
            label="Email ID"
            :rules="emailRules"
            :disabled="state == 'ins' ? false : true"
            required
          ></v-text-field>
        </div>
        <div class="pb-8">
          <span>가입하신 이메일 주소를 입력하시면 비밀번호를 변경 하실 수 있는 메일을 보내 드립니다.</span>
        </div>
        <div>
          <button class="btn-big btn-primary btn-block" @click="submitForm()">비밀번호 재 설정</button>
        </div>
      </div>
      <div class="loader" v-show="progressing">
        <div class="lds-ellipsis">
          <img src="/static/images/m.png" width="40px" alt="M">
          <div></div><div></div><div></div><div></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'

export default {
  name: 'findPass1',
  data () {
    return {
      state: 'ins',
      emailId: '',
      frontend: 'shopEx',
      sendType: 'Email',
      emailRules: [
        v => !!v || '이메일 아이디를 입력해주세요',
        v => /.+@.+/.test(v) || '이메일 형식에 맞춰 주세요'
      ],
      progressing: false
    }
  },
  methods: {
    submitForm() {
      this.progressing = true

      const sForm = new FormData()
      sForm.append('email', this.emailId)
      sForm.append('sendType', this.sendType)
      sForm.append('frontend', this.frontend)

      axios.post('/api/resetPw', sForm).then(({ data }) => {
        alert('메일이 발송되었습니다.')
        this.progressing = false
        this.$router.push('/login')
      }).catch((error) => {
        this.progressing = false
        alert(error.response.data.message)
      })
    },
    back() {
      this.$router.go(-1)
    }
  }
}
</script>
