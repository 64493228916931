import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import store from '@/store/index'
import axios from 'axios'

import Meta from 'vue-meta'

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

axios.defaults.baseURL = process.env.VUE_APP_BACKEND
axios.defaults.headers.common.Accept = 'application/json'
Vue.prototype.$http = axios

Vue.config.productionTip = false

axios.defaults.headers.common.Company = '0'

const token = localStorage.getItem('token')
if (token) {
  axios.defaults.headers.common.Authorization = token
}

axios.interceptors.request.use(
  async function (config) {
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// for multiple requests
let isRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom, i) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })
  failedQueue = []
}

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    const originalRequest = error.config
    if (error.response.status === 403 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject })
        }).then(token => {
          originalRequest.headers.Authorization = token
          return axios(originalRequest)
        }).catch(err => {
          return Promise.reject(err)
        })
      }

      originalRequest._retry = true
      isRefreshing = true

      const refreshToken = localStorage.getItem('refresh')
      return new Promise(function (resolve, reject) {
        axios.post('/api/refresh', { refreshToken: refreshToken }).then(({ data }) => {
          localStorage.setItem('token', data.token)
          // localStorage.setItem('refresh', data.refreshToken)
          axios.defaults.headers.common.Authorization = data.token
          originalRequest.headers.Authorization = data.token
          processQueue(null, data.token)
          resolve(axios(originalRequest))
        }).catch((err) => {
          store.dispatch('userStore/logout')
          router.push('/login')
          processQueue(err, null)
          reject(err)
        }).finally(() => {
          isRefreshing = false
        })
      })
    }
    return Promise.reject(error)
  }
)

Vue.use(Meta, {
  attribute: 'data-vue-meta'
  // vmid를 사용하기 위한 옵션
})

Vue.use(Vuetify)

new Vue({
  router,
  store,
  vuetify: new Vuetify({
    customVariables: ['~/assets/variables.scss'],
    icons: {
      iconfont: 'mdi'
    },
    themes: {
      // light 테마로 컬러 수정
      light: {
        primary: '#194278',
        accent: '#CE0000',
        error: '#CE0000'
      }
    }
  }),
  render: h => h(App)
}).$mount('#app')
