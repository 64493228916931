<template>
  <div class="wrapper">
    <section class="key-visual">
      <div class="key-text">
        <span class="key-tit1">정기적으로 꾸준히 판매 할 수 있는</span>
        <span class="key-tit2">정기배송 서비스</span>
        <span class="key-tit3">쉽고 간편하게 만들어 보세요</span>
        <a href="/features" class="key-btn">살펴보기</a>
      </div>
    </section>
    <section class="sec-key-mes grey-bg">
      <div class="container-limit">
        <h2 class="b-title block-center">ShopEx와 함께라면<br>구독 서비스 어렵지 않아요</h2>
        <p class="bs-text block-center">고객님이 손쉽게 구독 쇼핑몰을 구축하고 운영 하실 수 있도록<br>쉽지만 강력한 기능을 제공하고 있습니다</p>
        <ul class="key-item-list">
          <li class="key-item">
            <img src="/static/images/icon_card.png" alt="Card Icon">
            <span class="tt">Pay Hub</span>
            <span class="ts">다양한 결제 수단 지원과 간편한 결제 주기 설정 등<br>고객의 상황에 맞는 ‘유연한 결제’를 지원 합니다.</span>
          </li>
          <li class="key-item">
            <img src="/static/images/icon_monitor.png" alt="Monitor Icon">
            <span class="tt">Easy Office</span>
            <span class="ts">복잡한 판매관리 시스템을 고객에 필요한 정보만 모아<br>한눈에 쉽고 빠르게 관리 하실 수 있도록 UI를 구성 하였습니다.</span>
          </li>
          <li class="key-item">
            <img src="/static/images/icon_site.png" alt="Website Icon">
            <span class="tt">Shop Builder</span>
            <span class="ts">UI디자인과 프로그래밍을 모르셔도 간단한 설문을 통해<br>구독 서비스를 손쉽게 오픈 하실 수 있습니다.</span>
          </li>
          <li class="key-item">
            <img src="/static/images/icon_bulb.png" alt="Bulb Icon">
            <span class="tt">Ai Helper</span>
            <span class="ts">고객님의 서비스를 Ai가 실시간으로 진단하고 분석해<br>더 많은 판매를 하실 수 있도록 판매 지원 피드백을 드립니다.</span>
          </li>
        </ul>
      </div>
    </section>
    <section class="point">
      <div class="container-limit">
        <div class="point-cont1">
          <div class="img-wrap">
            <img src="/static/images/po-img1.png" alt="Pay Image">
          </div>
          <div class="cont-wrap">
            <h2 class="b-title">유연한 결제 시스템 지원</h2>
            <p>결제 시스템을 위해 서비스 신청과 등록비, 관리비 등을 PG사에 지불하지 않으셔도 카드결제, 네이버페이, 카카오페이로 손쉽게 정기구독 결제가 가능 합니다.</p>
          </div>
        </div>
      </div>
      <div class="container-limit">
        <div class="point-cont1">
          <div class="cont-wrap">
            <h2 class="b-title">상품만 준비 하세요!</h2>
            <p>판매자 정보와 판매 상품에 대한 정보를 설문을 통해 작성해 주시면 ShopEx가 서비스 세팅을 완료한 후 이메일을 통해 안내 드립니다.</p>
            <span>ㆍ 소요기간 : 최대 0일</span>
          </div>
          <div class="img-wrap">
            <img src="/static/images/po-img2.png" alt="Pay Image">
          </div>
        </div>
      </div>
      <div class="grey-bg point-cont2">
        <div class="container-limit">
          <h2 class="b-title block-center">중요 정보는 대시보드를 통해<br>한눈에 보여 드려요</h2>
          <p class="bs-text block-center">
            복잡한 기존 쇼핑몰 관리자 화면을 ShoEx는 서비스 관리자의 대시보드를 통해서
            핵심 정보를 한눈에 파악하고 대처 할 수 있도록 제공해 드리고 있습니다.
          </p>
          <div class="img-wrap"><img src="/static/images/po-img3.png" width="100%" alt="Dashboard Image"></div>
        </div>
      </div>
      <div class="point-cont2">
        <div class="container-limit">
          <h2 class="b-title block-center">서비스, 매출, 고객관리 및 진단은<br>Ai에게 맡겨 주세요</h2>
          <p class="bs-text block-center">
            Ai Helper가 고객님의 서비스를 진단해 더 많은 매출을 올릴 수 있도록<br>
            타겟 고객 분석과 이벤트 일정 등의 알람으로 도움을 드립니다.
          </p>
          <div class="img-wrap"><img src="/static/images/po-img4.png" width="100%" alt="Dashboard Image"></div>
        </div>
      </div>
    </section>
    <section>
      <v-carousel
        hide-delimiter-background
        show-arrows-on-hover
        height:500
        class="carousel-comment"
      >
        <v-carousel-item
          v-for="(slide,idx) in slides"
          :key="idx"
        >
          <v-sheet
            height="100%"
            :color="background"
          >
            <div class="comment-item">
              <div class="img-wrap">
                <img :src="slide.img"/>
              </div>
              <h4>{{ slide.name }}</h4>
              <p>{{ slide.contents }}</p>
            </div>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </section>
    <section class="shop_start">

      <div class="start-cont d-flex flex-column justify-center align-center">
        <h2>소상공인을 위한 정기구독 제작 서비스<br>ShopEx로 정기구독 서비스를 시작해 보세요</h2>
        <a href="/serviceRegister" class="key-btn">시작하기</a>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'home',
  data () {
    return {
      background:"#F9FBFE",
      slides:[
        {name: 'ninebillion', contents:'소상공인들도 ShopEx를 통해 구독경제에 손쉽게 참여해 보세요', img:'/static/images/profile_pic.png'},
        {name: 'BOHO', contents:'IT지식이 없이 정기구독 판매를 시작할 수 있었습니다.', img:'/static/images/profile_pic2.png'},
      ]
    }
  }
}
</script>
