import axios from 'axios'

export default {
  namespaced: true,
  state: {
    status: '',
    token: localStorage.getItem('token') || null,
    user: [],
    oauth2User: {
      properties: {
        nickname: null
      },
      kakao_account: {
        email: null,
        age_range: null,
        birthday: null,
        gender: null
      }
    }
  },
  mutations: {
    auth_request (state) {
      state.status = 'loading'
    },
    auth_success (state, data) {
      state.status = 'success'
      state.token = data.token
      state.user = data.user
    },
    auth_error (state) {
      state.status = 'error'
    },
    logout (state) {
      state.status = ''
      state.token = ''
      state.user = null
    },
    oauth2User (state, data) {
      state.oauth2User = data
    }
  },
  actions: {
    login ({ commit }, detail) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios.post('/api/login', detail).then(({ data }) => {
          localStorage.setItem('token', data.token)
          localStorage.setItem('refresh', data.refreshToken)
          axios.defaults.headers.common.Authorization = data.token
          localStorage.removeItem('tempuid')
          commit('auth_success', data)
          resolve(data)
        }).catch((error) => {
          commit('auth_error')
          localStorage.removeItem('token')
          localStorage.removeItem('refresh')
          reject(error.response.data.message)
        })
      })
    },
    oauth2Login ({ commit }, accessToken) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios.post('/api/oauth2Login', { accessToken: accessToken, provider: 'kakao', tempUserId: '' }).then(({ data }) => {
          if (data.isUser === 'yes') {
            localStorage.setItem('token', data.token)
            localStorage.setItem('refresh', data.refreshToken)
            axios.defaults.headers.common.Authorization = data.token
            commit('auth_success', data)
          } else {
            commit('oauth2User', data.kakaoProfile)
          }
          resolve(data)
        }).catch((error) => {
          commit('auth_error')
          localStorage.removeItem('token')
          reject(error.response.data.message)
        })
      })
    },
    logout ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('logout')
        localStorage.removeItem('token')
        localStorage.removeItem('refresh')
        delete axios.defaults.headers.common.Authorization
        resolve()
      })
    }
  },
  getters: {
    // isLogin: state => !!state.token,
    isLogin (state) {
      return !!state.token
    },
    user: state => state.user,
    oauth2User: function (state) {
      return state.oauth2User
    }
  }
}
