<template>
  <div class="wrap">
    <section class="sub_visual myService_v">
      <div class="sub-visual-cont">
        <h2>내 서비스</h2>
        <span>서비스 관리와 정보를 관리 하실 수 있습니다.</span>
      </div>
    </section>
    <section class="container-limit my-service">
      <v-row v-show="loadingComplete">
        <v-col cols="12" md="3">
          <div class="my-info-box">
            <h3 class="m-title">기본정보</h3>
            <div class="inner-info-box">
              <div class="info-box-item">
                <span class="tt">아이디</span>
                <span class="ts">{{ emailAddress }}</span>
              </div>
            </div>
            <!-- 서비스 신청 후 서비스 정보 노출  -->
            <div v-if="companyInfo.companyStatus && companyInfo.companyStatus != 'Ready'">
              <div class="inner-info-box">
                <div class="info-box-item">
                  <span class="tt">서비스 명</span>
                  <span class="ts">{{ companyInfo.companyConfig.serviceName }}</span>
                </div>
              </div>
              <div class="inner-info-box">
                <div class="info-box-item">
                  <span class="tt">스토어 주소</span>
                  <span class="ts">{{ companyInfo.companyConfig.serviceDomain }}</span>
                </div>
              </div>
              <div class="inner-info-box">
                <div class="info-box-item">
                  <span class="tt">서비스 개설일</span>
                  <span class="ts">{{ companyInfo.companyConfig.serviceOpenDate }}</span>
                </div>
              </div>
            </div>
            <v-btn v-if="companyInfo.companyStatus" class="mt-4" color='primary' elevation="0" rounded x-large
                   @click="goMyInfo()">사업자
              정보수정
            </v-btn>
          </div>
        </v-col>

        <v-col cols="12" offset-lg="1" md="9" lg="8">

          <!-- 처음 내 서비스 진입 시 -->
          <div class="svc-cont d-flex flex-column justify-center text-center align-center"
               v-if="!companyInfo.companyStatus || companyInfo.companyStatus == 'Ready'">
            <div class="img-wrap">
              <img src="/static/images/myservice-img1.png" alt="서비스 신청 이미지">
            </div>
            <div class="text-cont">
              <h3>서비스 신청하기</h3>
              <span>아직 서비스를 신청하신 정보가 없습니다<br>구독 서비스를 시작해 보세요</span>
            </div>
            <button class="btn-big btn-primary pl-12 pr-12" @click="goServiceRegister()">서비스 신청하기</button>
          </div>

          <!-- 서비스 신청 후 서비스 개설 중  -->
          <div class="svc-cont d-flex flex-column justify-center text-center align-center"
               v-if="companyInfo.companyStatus == 'Registered' || companyInfo.companyStatus == 'Making'">
            <div class="img-wrap">
              <img src="/static/images/myservice-img2.png" alt="서비스 제작 중 이미지">
            </div>
            <div class="text-cont">
              <h3>스토어 제작 중</h3>
              <span>고객님이 응답해주신 내용으로 스토어를 세팅 중에 있습니다.<br>세팅이 완료되면 고객님 <strong>이메일</strong>로 안내해 드리겠습니다.
              </span>
            </div>
            <button class="btn-big btn-primary pl-12 pr-12" @click="goHome()">홈으로 가기</button>
          </div>

          <!-- 서비스 개설 후 -->
          <div class="manage-box" v-if="companyInfo.companyStatus == 'ING'">
            <h3 class="m-title">내 서비스</h3>
            <div class="manage-inner-wrap">
              <div class="inner-cont">
                <div class="inner-wrapper">
                  <div class="inner-icon">
                    <v-icon color="white">mdi-cog-outline</v-icon>
                  </div>
                  <div class="cont-wrap">
                    <span class="tt">스토어 관리하기</span>
                    <span class="ts">Easy Office를 통해 고객님의 정기배송 서비스를 관리 하실 수 있습니다.</span>
                  </div>
                </div>
                <button class="btn-big" @click="goServiceAdmin">스토어 관리</button>
              </div>
              <div class="inner-cont">
                <div class="inner-wrapper">
                  <div class="inner-icon">
                    <v-icon color="white">mdi-store</v-icon>
                  </div>
                  <div class="cont-wrap">
                    <span class="tt">내 스토어</span>
                    <span class="ts">내 정기구독 서비스 스토어로 이동 하실 수 있습니다.</span>
                  </div>
                </div>
                <button class="btn-big" @click="goServiceSite">내 스토어</button>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'myService',
  data() {
    return {
      userId: null,
      emailAddress: null,
      companyInfo: {
        companyConfig: {},
        companyStatus: null
      },
      loadingComplete: false
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      axios.get('/api/user/me').then(({data}) => {
        console.log(data.result)
        this.userId = data.result.userId
        this.emailAddress = data.result.emailAddress
        this.getServiceInfo()
      }).catch((error) => {
        this.loadingComplete = true
        console.log(error)
      })
    },
    getServiceInfo() {
      axios.get('/api/company/service/getCompanyByRegisterUserId/' + this.userId).then(({data}) => {
        console.log(data.result)
        if (data.result) {
          this.companyInfo = data.result
        }
        this.loadingComplete = true
      }).catch((error) => {
        console.log(error)
        this.companyInfo = null
        this.loadingComplete = true
      })
    },
    goHome() {
      this.$router.push('/')
    },
    goServiceRegister() {
      this.$router.push('/registerCompany')
    },
    goMyInfo() {
      this.$router.push('/myServiceMyInfo')
    },
    goServiceAdmin() {
      window.open(this.companyInfo.companyConfig.serviceAdminUrl, '_blank')
    },
    goServiceSite() {
      window.open('https://'+this.companyInfo.companyConfig.serviceDomain, '_blank')
    }
  }
}
</script>
