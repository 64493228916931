<template>
  <div class="wrap">
    <section class="sub_visual myService_reg">
      <div class="sub-visual-cont">
        <h2>정기구독 서비스 만들기</h2>
        <span>1. 사업자 정보를 입력해주세요</span>
      </div>
    </section>
    <section class="container-limit reg-service">
      <v-row no-gutters justify="center">
        <v-col cols="12" md="8" lg="6">
          <div class="flow-map">
            <div class="d-flex flex-row justify-space-between">
              <div class="flow-item">
                <div class="ball active">1</div>
                <span>사업자 정보입력</span>
              </div>
              <div class="flow-item">
                <div class="ball">2</div>
                <span>서비스 정보입력</span>
              </div>
              <div class="flow-item">
                <div class="ball">3</div>
                <span>서비스 신청완료</span>
              </div>
            </div>
            <hr>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters justify="center">
        <v-col cols="12" md="8" lg="6">
          <v-form>
            <div class="d-flex flex-row justify-space-between">
              <h3>사업자 정보 입력</h3>
              <span>필수 입력항목 입니다.</span>
            </div>
          </v-form>
        </v-col>
      </v-row>

    </section>
  </div>
</template>

<script>
export default {
  name: 'serviceRegister'
}
</script>
