<template>
<div>
  <div class="wrapper">
    <div class="key_visual">
      <div class="key_title">
        <div class="header_title">
          <img src="/static/images/img_its.png" alt="it's truly yours">
        </div>
        <span class="tt">
					이벤트에 참여해 주셔서<br>감사합니다.
				</span>
        <div class="gap"></div>
        <span class="ts">
					참여일 기준 <b>1주일 이내에 당첨 결과</b>가 발표됩니다!<br>
					당첨된 분들에게는 개별 연락 드릴 예정입니다.
				</span>
      </div>
      <div class="social_wrap">
        <div class="social_list">
          <a href="https://le-celle.com/"><img width="32px" src="/static/images/ico_lecelle.png" alt="르셀르 홈페이지"></a>
          <a href="https://gift.kakao.com/brand/11105"><img width="32px" src="/static/images/ico_kakao.png" alt="르셀르 카카오선물하기"></a>
          <a href="https://www.youtube.com/c/르셀르lecelle/featured"><img width="32px" src="/static/images/ico_youtube.png" alt="르셀르 유튜브"></a>
          <a href="https://www.instagram.com/lecelle.official/"><img width="32px" src="/static/images/ico_instagram.png" alt="르셀르 인스타그램"></a>
        </div>
      </div>
      <div class="bg_back"></div>
    </div>
    <div class="foot_info">
      <span class="tt">유의사항</span>
      <ul class="list">
        <li>ㆍ카카오톡 선물하기를 통해 선물받으시거나<br>나에게 선물하기로 구매하신<br>
          고객 대상으로 진행되는 프로모션입니다.<br>
          (선물/주문 건당 1회 참여 가능)
        </li>
        <li>ㆍ수정을 원하시면 새롭게 제출을 해주세요</li>
      </ul>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'eventComplete',
  metaInfo: {
    title: '르셀르 포토 리뷰 이벤트',
    link: [{rel: "preconnect", href: 'https://fonts.googleapis.com'},
      {rel: "preconnect", href: 'https://fonts.gstatic.com'},
      {
        rel: "stylesheet",
        href: 'https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&display=swap'
      }],
    meta: [
      {charset: 'utf-8'},
      {vmid: "event", name: "event", content: 'event'}
    ]
  },
}
</script>
<style scoped>
body { margin: 0; padding: 0; font-family: 'Noto Sans KR', sans-serif;}
* { box-sizing: border-box; -moz-box-sizing: border-box;}
.wrapper{margin:0 auto; width:100%; background:#F9F7EF; min-height:100%;}
.key_visual {height: 680px; position: relative;}
.key_title {z-index:2; position:absolute; width:100%; text-align:center;}
.key_title span {display:block;}
.key_title .header_title { padding:100px 60px 10px 60px;}
.key_title .tt {font-size:30px; font-weight:700; padding-bottom:20px; font-family: 'Noto Sans KR', sans-serif!important;}
.key_title .ts {font-size:18px; font-weight:300; font-family: 'Noto Sans KR', sans-serif!important;}

.key_title img {max-width:240px; width: 100%;}
.gap {width:80px; height:1px; border:none; margin:0 auto 30px auto; background:rgba(0,0,0,.3);}
.key_visual .main_item {position:absolute; bottom:-80px; z-index:3; width:100%; text-align:center;}
.main_item img {width:240px; padding-left:20px;}
.key_visual .bg_back { background: url("/static/images/finish_back.jpg") no-repeat; background-size: cover; position:absolute; height:680px; width:100%; z-index:1;}

.social_wrap {position:absolute; bottom:100px; z-index:2; width:100%;}
.social_list { display:flex; flex-direction:row; width:100%; justify-content:center; align-items:center;}
.social_list a {padding:20px;}

.foot_info {background:#534F4C; padding:30px 20px; text-align:center; color:#fff;}
.foot_info .tt {font-size:20px; font-weight:700; padding-bottom:10px; display:block; font-family: 'Noto Sans KR', sans-serif!important;}
.foot_info ul {margin:0; padding:0;}
.foot_info .list li{font-size:14px; list-style:none; line-height: 24px; font-weight:300; padding-bottom:15px; font-family: 'Noto Sans KR', sans-serif!important;}
</style>
