<template>
  <div class="wrap">
    <section class="sub_visual myService_v">
      <div class="sub-visual-cont">
        <h2>내 서비스</h2>
        <span>서비스 관리와 정보를 관리 하실 수 있습니다.</span>
      </div>
    </section>
    <section class="container-limit my-service">
      <v-row no-gutters justify="center">
        <v-col cols="12" sm="10" md="8" lg="6">
          <div class="reg-title-wrap">
            <h3>
              <v-icon color="#222">mdi-pencil</v-icon>
              사업자 정보수정
            </h3>
          </div>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="form.companyName"
                label="사업자 명"
                hint="사업자등록증 상의 회사명을 입력해주세요"
                prepend-inner-icon="mdi-domain"
              ></v-text-field>

              <v-text-field
                v-model="form.ceoName"
                label="대표자 명"
                hint="대표님의 성함을 입력해주세요"
                prepend-inner-icon="mdi-account"
              ></v-text-field>

              <v-row>
                <v-col cols="4">
                  <v-btn
                    large
                    color="primary btn-block"
                    radius
                    @click="showApi()"
                  >
                    <v-icon class="pr-1">mdi-magnify</v-icon>
                    주소검색
                  </v-btn>
                  <div id="wrap" ref="embed"
                       style="display:none;border:1px solid;width:500px;height:300px;margin:5px 0;position:relative">
                    <img src="//t1.daumcdn.net/postcode/resource/images/close.png" id="btnFoldWrap"
                         style="cursor:pointer;position:absolute;right:0;top:-1px;z-index:1"
                         @click="foldDaumPostcode" alt="접기 버튼">
                  </div>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="form.address1"
                    label=""
                    hide-details="true"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-text-field
                v-model="form.address2"
                label="상세주소"
                class="mt-4"
                hint="상세 주소를 입력해주세요"
                prepend-inner-icon="mdi-map-marker"
              ></v-text-field>

              <v-text-field
                v-model="form.telephone"
                label="전화번호"
                hint="회사 대표 번호를 입력하세요"
                prepend-inner-icon="mdi-phone"
              ></v-text-field>

              <v-text-field
                v-model="form.companyEmail"
                label="대표 이메일 주소"
                hint="회사 대표 이메일 주소를 입력하세요"
                prepend-inner-icon="mdi-email"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" lg="4">
              <button class="btn-big btn-primary btn-block" @click="modify()">수정</button>
            </v-col>
            <v-col cols="5" lg="4">
              <button class="btn-big btn-block" @click="goCancel()">취소</button>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'myService',
  data() {
    return {
      form: {
        userId: null,
        companyId: null,
        companyName: null,
        ceoName: null,
        address1: null,
        address2: null,
        zipcode: null,
        telephone: null,
        companyEmail: null
      }
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      axios.get('/api/user/me').then(({data}) => {
        console.log(data.result)
        this.form.userId = data.result.userId
        this.form.companyId = data.result.companyId
        this.getCompanyInfo()
      }).catch((error) => {
        console.log(error)
      })
    },
    getCompanyInfo() {
      axios.get('/api/company/service/getCompanyByRegisterUserId/' + this.form.userId).then(({data}) => {
        console.log(data.result)
        if (data.result) {
          this.form.companyId = data.result.companyId
          this.form.companyName = data.result.companyName
          this.form.ceoName = data.result.ceoName
          this.form.address1 = data.result.address1
          this.form.address2 = data.result.address2
          this.form.zipcode = data.result.zipcode
          this.form.telephone = data.result.telephone
          this.form.companyEmail = data.result.companyEmail
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    modify() {
      axios.post('/api/company/service/modifyCompany', this.form).then(({data}) => {
        console.log(data.result)
        alert('수정되었습니다.')
        this.$router.push('/myService')
      }).catch((error) => {
        alert(error.response.data.message)
      })
    },
    goCancel() {
      this.$router.push('myService')
    },
    foldDaumPostcode() {
      // eslint-disable-next-line camelcase
      const element_wrap = document.getElementById('wrap')
      element_wrap.style.display = 'none'
    },
    showApi() {
      // eslint-disable-next-line camelcase
      const element_wrap = document.getElementById('wrap')
      new window.daum.Postcode({
        oncomplete: (data) => {
          // 각 주소의 노출 규칙에 따라 주소를 조합한다.
          // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
          let addr // 주소 변수
          let extraAddr = '' // 참고항목 변수

          // 사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
          if (data.userSelectedType === 'R') { // 사용자가 도로명 주소를 선택했을 경우
            addr = data.roadAddress
          } else { // 사용자가 지번 주소를 선택했을 경우(J)
            addr = data.jibunAddress
          }

          // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
          if (data.userSelectedType === 'R') {
            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
            if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
              extraAddr += data.bname
            }
            // 건물명이 있고, 공동주택일 경우 추가한다.
            if (data.buildingName !== '') {
              extraAddr += (extraAddr !== '' ? ', ' + data.buildingName : data.buildingName)
            }
            // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
            if (extraAddr !== '') {
              extraAddr = ' (' + extraAddr + ')'
            }
          }
          // 우편번호와 주소 정보를 해당 필드에 넣는다.
          this.form.zipcode = data.zonecode // 5자리 새우편번호 사용
          this.form.address1 = addr + extraAddr

          element_wrap.style.display = 'none'
        },
        onresize: function (size) {
          element_wrap.style.height = size.height + 'px'
        },
        width: '100%',
        height:
          '100%'
      }).embed(element_wrap)
      element_wrap.style.display = 'block'
    }
  }
}
</script>
