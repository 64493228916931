import Vue from 'vue'
import Router from 'vue-router'
import store from './store/index'

import home from '@/views/home'
import join1 from '@/views/user/join1'
import join2 from '@/views/user/join2'
import join3 from '@/views/user/join3'
import findPass1 from '@/views/user/findPass1'
import changePW from '@/views/user/changePW'
import login from '@/views/user/login'
import csCenter from '@/views/service/csCenter'
import features from '@/views/service/features'
import myService from '@/views/service/myService'
import myServiceMyInfo from '@/views/service/myServiceMyInfo'
import priceTable from '@/views/service/priceTable'
import registerCompany from '@/views/service/registerCompany'
import registerFinish from '@/views/service/registerFinish'
import registerService from '@/views/service/registerService'
import serviceRegister from '@/views/service/serviceRegister'

import event from "@/views/event/event"
import eventComplete from "@/views/event/eventComplete"

const requireAuth = () => (from, to, next) => {
  if (store.getters['userStore/isLogin']) {
    return next()
  } else {
    next('/login?redirect=' + from.fullPath)
  }
}

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
    path: '/',
    name: 'home',
    component: home
  }, {
    path: '/join1',
    name: 'join1',
    component: join1
  }, {
    path: '/join2',
    name: 'join2',
    component: join2
  }, {
    path: '/join3',
    name: 'join3',
    component: join3
  }, {
    path: '/findPass1',
    name: 'findPass1',
    component: findPass1
  }, {
    path: '/changePW',
    name: 'changePW',
    component: changePW
  }, {
    path: '/login',
    name: 'login',
    component: login
  }, {
    path: '/csCenter',
    name: 'csCenter',
    component: csCenter
  }, {
    path: '/features',
    name: 'features',
    component: features
  }, {
    path: '/myService',
    name: 'myService',
    component: myService,
    beforeEnter: requireAuth()
  }, {
    path: '/myServiceMyInfo',
    name: 'myServiceMyInfo',
    component: myServiceMyInfo,
    beforeEnter: requireAuth()
  }, {
    path: '/priceTable',
    name: 'priceTable',
    component: priceTable
  }, {
    path: '/registerCompany',
    name: 'registerCompany',
    component: registerCompany
  }, {
    path: '/registerFinish',
    name: 'registerFinish',
    component: registerFinish
  }, {
    path: '/registerService',
    name: 'registerService',
    component: registerService,
    beforeEnter: requireAuth()
  }, {
    path: '/serviceRegister',
    name: 'serviceRegister',
    component: serviceRegister,
    beforeEnter: requireAuth()
  }, {
    path: '/lecelle/event',
    name: 'event',
    component: event
  }, {
    path: '/lecelle/eventComplete',
    name: 'eventComplete',
    component: eventComplete
  }, {
    path: '*',
    name: 'Error404',
    component: () => import('@/views/Error404')
  }]
})
