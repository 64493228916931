<template>
  <div class="wrap">
    <section class="sub_visual price_v">
      <div class="sub-visual-cont">
        <h2>요금제</h2>
        <span>ShopEx의 이용요금 안내 드립니다.</span>
      </div>
    </section>
    <section class="container-limit">
      <div class="price-table">
        <h3 class="page-title">
          <v-icon>mdi-tag-text-outline</v-icon>
          이용요금 안내
        </h3>
        <div class="price-info">
          <ul class="info-list">
            <li>
              베타 테스트 기간 중 서비스 개설은 무료 입니다.<br>
              <span class="info-text">베타 기간이 종료시 비용에 대해 안내 드립니다. (2022년하반기 예상)</span>
            </li>
            <li>소상공인을 위한 서비스로 서비스 신청 시 사업자등록번호를 입력 하셔야 합니다.</li>
            <li>계정 당 1개의 구독 서비스를 개설 하실 수 있습니다.</li>
            <li>간단한 정보 입력을 통해 서비스를 개설 하실 수 있습니다.</li>
          </ul>
          <button class="btn-big btn-primary" @click="goRegister()"
          >
            서비스 신청하기
          </button>
        </div>
        <div class="price-img hidden-sm-and-down">
          <img src="/static/images/price-img.png" alt="이용요금 안내 이미지">
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'priceTable',
  methods: {
    goRegister () {
      location.href = '/myService'
      // this.$router.push('/myService')
    }

  }
}
</script>
