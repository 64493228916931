<template>
  <div class="wrapper">
    <section class="sub_visual features_v">
      <div class="sub-visual-cont">
        <h2>주요기능</h2>
        <span>ShopEx의 주요 기능에 대해 안내 드립니다.</span>
      </div>
    </section>
    <section class="container-limit">
      <div class="feature-title">
        <div class="title-wrap">
          <div class="tit-icon">
            <img src="/static/images/icon_f_card.png" alt="Card Icon">
          </div>
          <h3>Pay Hub</h3>
        </div>
        <p>다양한 결제 수단 지원과 간편한 결제 주기 설정 등 고객의 상황에 맞는 ‘유연한 결제’를 지원 합니다.</p>
      </div>
      <h4 class="f-title">Pay Hub의 장점</h4>
      <div class="features-img-wrap">
        <div class="hidden-sm-and-down">
          <img src="/static/images/img_f_01b.png" width="100%" alt="payhub 장점 이미지">
        </div>
        <div class="hidden-md-and-up">
          <img src="/static/images/img_f_01s.png" width="100%" alt="payhub 장점 이미지">
        </div>
      </div>
    </section>
    <section class="container-limit">
      <div class="feature-title">
        <div class="title-wrap">
          <div class="tit-icon">
            <img src="/static/images/icon_f_monitor.png" alt="Moniotor Icon">
          </div>
          <h3>Easy Office</h3>
        </div>
        <p>복잡한 판매관리 시스템을 고객에 필요한 정보만 모아 한눈에 쉽고 빠르게 관리 하실 수 있도록 UI를 구성 하였습니다.</p>
      </div>
      <h4 class="f-title">정기구독에 최적화된 관리 시스템</h4>
      <div class="features-img-wrap">
        <div class="hidden-sm-and-down">
          <img src="/static/images/img_f_02b.png" width="100%" alt="Easy Office 장점 이미지">
        </div>
        <div class="hidden-md-and-up">
          <img src="/static/images/img_f_02s.png" width="100%" alt="Easy Office 장점 이미지">
        </div>
      </div>
      <span class="item-sub-text">Easy Office는 상품관리, 고객관리 등을 포함한 편리한 UI와 프로세스, 자동화를 갖춘 효율적인 관리 시스템 입니다.</span>
    </section>
    <section class="container-limit">
      <div class="feature-title">
        <div class="title-wrap">
          <div class="tit-icon">
            <img src="/static/images/icon_f_site.png" alt="Website Icon">
          </div>
          <h3>Shop Builder</h3>
        </div>
        <p>UI디자인과 프로그래밍을 모르셔도 간단한 설문을 통해 구독 서비스를 손쉽게 오픈 하실 수 있습니다.</p>
      </div>
      <v-row class="pt-6">
        <v-col cols="6" md="3">
          <div class="builder-item">
            <div class="build-icon"><img src="/static/images/img_f_03_1.png" alt="user icon"></div>
            <span>비 전문가도 쉽게 구독 서비스를 오픈 하실 수 있습니다.</span>
          </div>
        </v-col>
        <v-col cols="6" md="3">
          <div class="builder-item">
            <div class="build-icon"><img src="/static/images/img_f_03_2.png" alt="alarm icon"></div>
            <span>서비스 신청 작성 후 0일 이후에 이메일로 안내 드립니다.</span>
          </div>
        </v-col>
        <v-col cols="6" md="3">
          <div class="builder-item">
            <div class="build-icon"><img src="/static/images/img_f_03_3.png" alt="checking icon"></div>
            <span>설문 방식으로 누구나 쉽게 제작 하실 수 있습니다.</span>
          </div>
        </v-col>
        <v-col cols="6" md="3">
          <div class="builder-item">
            <div class="build-icon"><img src="/static/images/img_f_03_4.png" alt="gear icon"></div>
            <span>서비스 관리는 Easy Office를 통해 편리하게 관리 하실 수 있습니다.</span>
          </div>
        </v-col>
      </v-row>
    </section>
    <section class="container-limit pb-12">
      <div class="feature-title">
        <div class="title-wrap">
          <div class="tit-icon">
            <img src="/static/images/icon_f_bulb.png" alt="Bulb Icon">
          </div>
          <h3>Ai Helper</h3>
        </div>
        <p>고객님의 서비스를 Ai가 실시간으로 진단하고 분석해 더 많은 판매가 이루어 질 수 있도록 판매 지원 피드백을 드립니다.</p>
      </div>
      <div class="features-img-wrap">
        <div class="hidden-sm-and-down">
          <img src="/static/images/img_f_04b.png" width="100%" alt="Ai Helper 장점 이미지">
        </div>
        <div class="hidden-md-and-up">
          <img src="/static/images/img_f_04s.png" width="100%" alt="Ai Helper 장점 이미지">
        </div>
      </div>
      <span class="item-sub-text">Ai Helper는 머신 러닝을 통해 고객님의 데이터를 분석하여
                서비스 고객 이탈 감지, 고객가치(LTV)계산, 할인율 추천 등을 안내해 드리는
                ShopEx의 똑똑한 인공지능 도우미 입니다.</span>
    </section>
  </div>
</template>

<script>
export default {
  name: 'features'
}
</script>
