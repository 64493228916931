<template>
  <div class="wrapper">
    <div class="key_visual">
      <div class="key_title">
        <div class="header_title">
          <img src="/static/images/header-title.png" alt="review event image">
        </div>
        <div class="gap"></div>
        <span class="tt">
					정성들여 준비한 선물은<br>마음에 드시나요?
				</span>
        <span class="ts">
					카카오톡에 포토 후기를 올려주시면<br>
					<b>스타벅스 아메리카노</b><br>
					교환권을 선물해드립니다.
				</span>
      </div>
      <div class="main_item">
        <img src="/static/images/coffee_basic.png" alt="starbucks coffee image">
      </div>
      <div class="back_mov">
        <video autoplay loop muted playsinline width="100%">
          <source src="/static/images/back_leaf.mp4">
        </video>
      </div>
    </div>
    <div class="manual">
			<span class="btitle">
				<b>참여방법</b>
			</span>
      <ul class="step_list">
        <li>
          <div class="step_box">
            <div class="step_header">
              <span class="step_title">Step 1</span>
            </div>
            <span class="tt">
							카카오톡 선물함에서<br>
							포토 후기 작성
						</span>
            <img src="/static/images/step_item1.png" alt="Step1 icon" width="140px">
            <span class="ts">
							<b>제품 사진 2-3장</b>과 함께<br>
							제품 사용 후기를 남겨주세요
						</span>
          </div>
        </li>

        <li>
          <div class="step_box">
            <div class="step_header">
              <span class="step_title">Step 2</span>
            </div>
            <span class="tt">
							작성한 포토 후기<br>
							화면 스크린샷
						</span>
            <img src="/static/images/step_item2.png" alt="Step2 icon" width="140px">
            <span class="ts">
							꼭 후기를 <b>올린 날짜가 보이도록</b><br>
							스크린샷을 찍어주세요!
						</span>
            <div style="margin-bottom:-20px; text-align:center">
              <img src="/static/images/review_box.png" alt="Step2 icon" width="264px">
            </div>
          </div>
        </li>
        <li>
          <div class="step_box">
            <div class="step_header">
              <span class="step_title">Step 3</span>
            </div>
            <span class="tt">
							여기 이벤트 페이지에<br>
							후기 인증
						</span>
            <img src="/static/images/step_item3.png" alt="Step3 icon" width="140px">
            <span class="ts">
							당첨자분들께는 1주일 이내에<br>
							개별 연락을 드립니다
						</span>
          </div>
        </li>
      </ul>
      <span class="step_info">
				*리뷰 작성일자와 이벤트 참여일자가<br>
				동일한 건에 한해 참여 가능합니다.
			</span>
      <div class="btn-wrap">
        <button class="kakao_btn" @click="goKakaoReview()">카카오톡 리뷰 작성하러 가기</button>
      </div>
    </div>
    <div class="form">
			<span class="btitle">
				선물을 받으실 수 있는<br>
				<b>연락처를</b> 알려주세요!
			</span>
      <div class="form_group">
        <div class="form_item">
          <input type="text" placeholder="참여자 이름" v-model="form.nickname">
        </div>
        <div class="form_item">
          <input type="tel" placeholder="휴대전화 번호" v-model="form.mobilePhone"
                 onkeyup="this.value=this.value.replace(/\D/g,'');">
        </div>
        <div class="form_item upload_file">
          <label for="photo_upload"><img src="/static/images/icon_pic.png" alt="picture icon" width="18px"> 리뷰사진
            첨부</label>
          <input type="file" id="photo_upload" ref="reviewImage" @change="reviewImageSelect">
        </div>
        <span class="info-text">오늘 올리신 후기화면 스크린샷 사진을 첨부 해주세요</span>
        <div class="photo" v-if="form.preloadImage">
          <img :src="form.preloadImage" alt="리뷰이미지">
        </div>
      </div>
      <div class="reg_wrap">
        <div class="myinfo">
          <ol>
            <li>본 이벤트는 카카오채널 르셀르를 추가하신 분 대상으로 진행되는 이벤트입니다.</li>
            <li>개인정보의 수집·이용 목적: 이벤트 진행 및 경품 지급</li>
            <li>수집하는 개인정보의 항목: 이름, 연락처</li>
            <li>개인정보의 보유·이용 기간: 이벤트 종료 후 즉시 파기</li>
            <li>제공해주신 개인정보는 이벤트 참여 및 경품 발송용으로만 사용되며, 다른 목적으로 이용되지 않습니다.</li>
            <li>개인정보의 수집 및 이용에 대한 동의를 거부할 수 있으며, 이 경우 이벤트 참여가 제한됩니다.</li>
          </ol>
          <span>※ 제출해주신 정보는 당첨시 선물을 보내드리는 용도로만 활용됩니다.</span>
        </div>
        <div class="form_item">
          <input type="checkbox" v-model="isAgree">
          <span>개인정보 수집 및 이용에 동의 합니다.</span>
        </div>
        <div class="btn-wrap">
          <button class="primary_button" @click="addEventComment">이벤트 응모 완료</button>
        </div>
      </div>
    </div>
    <div class="foot_info">
      <span class="tt">유의사항</span>
      <ul class="list">
        <li>ㆍ카카오톡 선물하기를 통해 선물받으시거나<br>나에게 선물하기로 구매하신<br>
          고객 대상으로 진행되는 프로모션입니다.<br>
          (선물/주문 건당 1회 참여 가능)
        </li>
        <li>ㆍ수정을 원하시면 새롭게 제출을 해주세요</li>
      </ul>
    </div>
    <div class="loading_wrap" v-show="loading">
      <img src="/static/images/Loading-bar.gif" width="32px" alt="loading bar">
      <span class="text">Loading</span>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'event',
  metaInfo: {
    title: '르셀르 포토 리뷰 이벤트',
    link: [{rel: "preconnect", href: 'https://fonts.googleapis.com'},
      {rel: "preconnect", href: 'https://fonts.gstatic.com'},
      {
        rel: "stylesheet",
        href: 'https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&display=swap'
      }],
    meta: [
      {charset: 'utf-8'},
      {vmid: "event", name: "event", content: 'event'}
    ]
  },
  data() {
    return {
      loading: false,
      isAgree: false,
      form: {
        eventId: 1,
        nickname: null,
        mobilePhone: null,
        commentFile1: undefined,
        preloadImage: undefined
      }
    }
  },
  methods: {
    addEventComment() {
      if (!this.form.nickname) {
        alert('이름을 입력해 주세요.')
        return
      }
      if (!this.form.mobilePhone) {
        alert('휴대전화 번호를 입력해 주세요.')
        return
      }

      if (!this.isAgree) {
        alert('개인정보 수집 및 이용에 대해 동의해 주셔야 이벤트 응모가 가능 합니다.')
        return
      }
      this.loading = true
      this.form.mobilePhone = this.form.mobilePhone.replace(/-/gi, '')

      const formData = new FormData()
      formData.append('eventId', this.form.eventId)
      formData.append('nickname', this.form.nickname)
      formData.append('mobilePhone', this.form.mobilePhone)

      if (this.form.commentFile1 !== undefined) {
        formData.append('commentFile1', this.form.commentFile1)
      } else {
        alert('리뷰 이미지를 등록해주세요.')
        return
      }

      axios.post('/api/event/participateEvent', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(({data}) => {
        console.log(data)
        alert('이벤트 참여 완료되었습니다. ')
        this.loading = false
        this.$router.push('/lecelle/eventComplete')
      }).catch((error) => {
        console.log(error.response)
        alert(error.response.data.message)
        this.loading = false
      })
    },
    reviewImageSelect(event) {
      const input = event.target
      // this.form.commentFile1 = this.$refs.reviewImage.files[0]
      if (input.files && input.files[0]) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.form.preloadImage = e.target.result
          this.form.commentFile1 = this.$refs.reviewImage.files[0]
        }
        reader.readAsDataURL(input.files[0])
      }
    },
    goKakaoReview() {
      const link = 'https://gift.kakao.com/giftbox/review'

      window.open(link)
    },
    removeImage() {
      this.form.preloadImage = undefined
      this.form.commentFile1 = undefined
    }
  }
}
</script>
<style scoped>
body {
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans KR', sans-serif;
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.wrapper {
  margin: 0 auto;
  width: 100%;
  background: #F9F7EF;
  font-family: 'Noto Sans KR', sans-serif !important;
}

.key_visual {
  height: 640px;
  position: relative;
  margin-bottom: 100px;
}

.key_title {
  z-index: 2;
  position: absolute;
  width: 100%;
  text-align: center;
}

.key_title span {
  display: block;
}

.key_title .header_title {
  padding: 40px 60px 20px 60px;
}

.key_title .tt {
  font-size: 28px;
  font-weight: 700;
  padding-bottom: 20px;
  font-family: 'Noto Sans KR', sans-serif !important;
}

.key_title .ts {
  font-size: 16px;
  font-weight: 400;
  font-family: 'Noto Sans KR', sans-serif !important;
}

.key_title img {
  max-width: 270px;
  width: 100%;
}

.gap {
  width: 80px;
  height: 1px;
  border: none;
  margin: 0 auto 20px auto;
  background: rgba(0, 0, 0, .3);
}

.key_visual .main_item {
  position: absolute;
  bottom: -80px;
  z-index: 3;
  width: 100%;
  text-align: center;
}

.main_item img {
  width: 240px;
  padding-left: 20px;
}

.back_mov {
  height: 640px;
  overflow: hidden;
  max-width: 720px;
  margin: 0 auto;
}

.step_list {
  padding: 0;
  margin: 0;
}

.step_list li {
  list-style: none;
  padding: 20px 35px 0 35px;
  position: relative;
}

.step_box {
  background: #fff;
  border-radius: 18px;
  text-align: center;
  padding-bottom: 30px;
  min-height: 280px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
  margin: 40px auto 0 auto;
  max-width: 420px;
}

.step_tip {
  text-align: center;
  margin: 0 auto;
  max-width: 420px;
}

.step_box span {
  text-align: center;
  display: block;
  letter-spacing: -1px;
}

.step_box img {
  margin: 10px;
}

.step_box .step_header {
  position: absolute;
  top: -20px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.step_title {
  background: #000;
  color: #fff;
  width: 100px;
  margin: 0 auto;
  line-height: 40px;
  font-weight: 700;
  border-radius: 20px;
  height: 40px;
  font-size: 20px;
  letter-spacing: -1px;
  display: block;
  text-align: center;
}

.step_box .tt {
  font-size: 24px;
  font-weight: 700;
}

.step_box .ts {
  font-size: 16px;
  font-weight: 400;
  color: #333;
}

.step_box .ts > b {
  box-shadow: inset 0 -8px 0 #FFED4C;
}

.step_info {
  color: #E80000;
  text-align: center;
  width: 100%;
  display: block;
  padding-top: 20px;
  font-size: 16px;
}

.info-text {
  color: #888;
  text-align: center;
  display: block;
  font-size: 14px;
}

.btn-wrap {
  padding: 30px 35px 60px 35px;
  text-align: center;
  display: block;
}

.kakao_btn {
  width: 100%;
  height: 70px;
  line-height: 70px;
  border-radius: 8px;
  max-width: 420px;
  font-size: 20px;
  font-weight: 700;
  background: #FFE812;
  border: none;
  font-family: 'Noto Sans KR', sans-serif !important;
}

.kakao_btn:hover {
  background: #F5DD00;
}

.form {
  background: #fff;
  padding-top: 50px;
  width: 100%;
}

.wrapper .btitle {
  text-align: center;
  display: block;
  font-size: 30px;
  letter-spacing: -1px;
  padding-bottom: 20px;
  font-family: 'Noto Sans KR', sans-serif !important;
}

.wrapper .btitle > b {
  box-shadow: inset 0 -12px 0 #FFED4C;
}

.form .form_group {
  width: 100%;
  max-width: 490px;
  margin: 0 auto;
  padding: 30px 35px 60px 35px;
}

.form_group .form_item {
  width: 100%;
  padding-bottom: 25px;
}

.form_group .form_item:last-child {
  padding-bottom: 15px;
}

.form_group .form_item input {
  width: 100%;
  height: 60px;
  border: none;
  background: #F6F5F1;
  text-align: center;
  color: #4B3D31;
  font-size: 20px;
  border-radius: 4px;
  box-shadow: inset 0 2px 0 rgba(0, 0, 0, .08)
}

.form_item input[type="file"] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.form_item input[type="checkbox"] {
  margin: 0;
  vertical-align: middle;
}

.upload_file label {
  display: inline-block;
  width: 100%;
  border: 1px solid #4B3D31;
  border-radius: 4px;
  text-align: center;
  height: 60px;
  line-height: 60px;
  font-size: 20px;
  color: #4B3D31;
}

.reg_wrap {
  background: #FAFAFA;
  padding: 30px 0 0 0;
  display: block;
  font-size: 12px;
}

.primary_button {
  width: 100%;
  max-width: 420px;
  height: 70px;
  line-height: 70px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  background: #4B3D31;
  border: none;
  font-family: 'Noto Sans KR', sans-serif !important;
}

.myinfo {
  padding: 0 35px;
  width: 100%;
  margin: 0 auto;
  max-width: 450px;
}

.myinfo .form_item {
  text-align: center;
}

.myinfo ol {
  font-size: 12px;
  color: #666;
  padding: 10px 0 0 0;
  margin: 0
}

.myinfo ol li {
  padding-bottom: 3px;
  word-break: keep-all;
}

.myinfo span {
  color: #E80000
}

.reg_wrap .form_item {
  font-size: 14px;
  text-align: center;
  padding-top: 30px
}

.photo {
  width: 240px;
  padding-top: 30px;
  margin: 0 auto;
}

.photo img {
  width: 100%;
  border-radius: 8px;
}

.foot_info {
  background: #534F4C;
  padding: 30px 20px;
  text-align: center;
  color: #fff;
  font-family: 'Noto Sans KR', sans-serif !important;
}

.foot_info .tt {
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 10px;
  display: block;
  font-family: 'Noto Sans KR', sans-serif !important;
}

.foot_info ul {
  margin: 0;
  padding: 0;
}

.foot_info .list li {
  font-size: 14px;
  list-style: none;
  line-height: 24px;
  font-weight: 300;
  padding-bottom: 15px;
  font-family: 'Noto Sans KR', sans-serif !important;
}

.loading_wrap {
  background: rgba(255, 255, 255, .8);
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loading_wrap .text {
  font-size: 11px;
}
</style>
