<template>
  <div class="wrap">
    <section class="sub_visual customer_v">
      <div class="sub-visual-cont">
        <h2>고객 센터</h2>
        <span>FAQ와 ShopEx와 관련한 문의를 하실 수 있습니다.</span>
      </div>
    </section>
    <section class="container-limit">
      <div class="customer-wrap">
        <h3 class="page-title">
          <v-icon>mdi-comment-multiple-outline</v-icon>
          FAQ
        </h3>
        <v-row justify="center">
          <v-col cols="12" lg="8">
            <v-expansion-panels flat>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="accor-header">
                    <span class="category">회원</span>
                    <span class="main-text">회원가입은 어떻게 할 수 있나요?</span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="accor-content">
                  회원가입은 로그인 페이지에 진입하셔서 '회원가입'을 클릭하시면 이메일주소와 비밀번호 설정만으로 가입 하실 수 있습니다.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="accor-header">
                    <span class="category">회원</span>
                    <span class="main-text">신청한 사업자 정보의 수정은 어떻게 하나요?</span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="accor-content">
                  로그인 후 '내 서비스'메뉴에 진입 하시면 '사업자 정보수정'버튼을 통해 입력하신 고객님의 정보를 수정 하실 수 있습니다.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="accor-header">
                    <span class="category">회원</span>
                    <span class="main-text">비밀번호를 변경하고 싶습니다.</span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="accor-content">
                  현재의 비밀번호의 변경을 원하시면 ‘내 서비스’페이지에서 변경 하실 수 있습니다.
                  왼쪽 기본정보 영역의 ‘비밀번호 변경’을 선택 하시면 현재 비밀번호와 새로운 비밀번호를 입력 함으로써 변경 하실 수 있습니다.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="accor-header">
                    <span class="category">서비스</span>
                    <span class="main-text">ShopEx는 어떤 서비스 인가요?</span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="accor-content">
                  ShopEx는  손쉽게 정기구독 서비스를 개설 할 수 있도록 돕는 소상공인을 위한 정기구독 스토어 제작 서비스 입니다.
                  스토어 제작은 코딩 지식이 없어도 몇 번의 클릭과 사업자의 정보 입력 만으로 온라인 상에서  정기구독 서비스를 만드실 수 있습니다.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="accor-header">
                    <span class="category">서비스</span>
                    <span class="main-text">서비스 신청은 어떻게 할 수 있나요?</span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="accor-content">
                  정기구독 서비스 신청은 로그인 후 '내 서비스'메뉴로 진입하셔서 '서비스 신청하기'버튼을 통해 신청 할 수 있습니다.
                  서비스는 1차로 사업자 정보를 입력, 2차로 만드시려고 하는 서비스 정보를 입력하시면 서비스 신청이 완료 됩니다.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="accor-header">
                    <span class="category">서비스</span>
                    <span class="main-text">사업자 정보 없이 정기구독 서비스를 만들 수 없나요?</span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="accor-content">
                  ShopEx는 소상공인을 위한 서비스로 사업자 등록이 되어 있으신 분들로만 서비스를 이용 하실 수 있습니다.
                  일반 고객은 이용 하실 수 없는 점 이해해 주시기 바랍니다.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="accor-header">
                    <span class="category">서비스</span>
                    <span class="main-text">서비스 신청은 어떻게 할 수 있나요?</span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="accor-content">
                  정기구독 서비스 신청은 로그인 후 '내 서비스'메뉴로 진입하셔서 '서비스 신청하기'버튼을 통해 신청 할 수 있습니다.
                  서비스는 1차로 사업자 정보를 입력, 2차로 만드시려고 하는 서비스 정보를 입력하시면 서비스 신청이 완료 됩니다.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="accor-header">
                    <span class="category">서비스</span>
                    <span class="main-text">이용요금은 어떻게 청구 되나요?</span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="accor-content">
                  현재 ShopEx는 베타 버전으로 운영되고 있습니다. 모든 서비스를 무료로 이용 가능하시며, 추후 요금이 발생 되실 경우 이메일을 통해 공지해 드리겠습니다.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="accor-header">
                    <span class="category">서비스</span>
                    <span class="main-text">제작한 정기구독 서비스의 관리는 어떻게 하나요?</span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="accor-content">
                  스토어의 제품 등록과 판매, 회원 관리는 관리자 사이트를 통해 하실 수 있습니다.
                  관리자 페이지 접속은 ‘내 서비스’ 페이지의  ‘스토어 관리하기’버튼을 통해 이동 하실 수 있습니다.
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <div class="contact">
              <h3 class="page-sub-title"><v-icon>mdi-comment-question-outline</v-icon> 문의하기</h3>
              <span class="st">서비스와 관련한 문의는 고객센터로 연락 주시거나 아래의 이메일로 보내 주시면 신속하게 답변 드리겠습니다.</span>
              <v-row>
                <v-col cols="12" sm="6">
                  <div class="contact-box">
                    <v-icon>mdi-phone-in-talk</v-icon>
                    <span class="tt">ShopEx 고객센터</span>
                    <a href="tel:07086569610">070-8656-9610</a>
                    <span class="st">평일 : 10:00 ~ 17:00 (주말 및 공휴일 휴무)</span>
                  </div>
                </v-col>
                <v-col cols="12" sm="6">
                  <div class="contact-box">
                    <v-icon>mdi-email-outline</v-icon>
                    <span class="tt">이메일문의</span>
                    <a href="mailto:contact@shopex.com">contact@shopex.com</a>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'csCenter'
}
</script>
