<template>
  <section class="join-wrap">
    <div class="hidden-box">
      <div class="inner-box">
        <div class="tit-wrap">
          <h3>ShopEx 회원가입</h3>
          <span>이메일과 카카오톡 ID로 가입 하실 수 있습니다.</span>
        </div>
        <v-row class="ma-auto join-sel">
          <v-col cols="12" xs="12" sm="6">
            <button class="mail-btn" @click="registerEmail()">이메일로 회원가입</button>
          </v-col>
          <v-col cols="12" xs="12" sm="6">
            <button class="kakao-btn" @click="kakaoLogin()">카카오로 회원가입</button>
          </v-col>
        </v-row>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'join1',
  methods: {
    ...mapGetters({
      isLogin: 'userStore/isLogin'
    }),
    registerEmail() {
      this.$router.push('/join2')
    },
    kakaoLogin() {
      // eslint-disable-next-line no-undef
      Kakao.Auth.login({
        success: this.kakaoLoginSuccessHandler.bind(this),
        fail: function (err) {
          console.log(JSON.stringify(err))
        }
      })
    },
    kakaoLoginSuccessHandler: function (res) {
      this.$nextTick(function () {
        this.$store.dispatch('userStore/oauth2Login', res.access_token).then(() => {
          if (this.isLogin()) {
            location.href = '/'
            // this.$router.push('/')
          } else {
            this.$router.push({ name: 'join2' })
          }
        }).catch((error) => {
          this.errorMessage = error.message
        })
      })
    }

  }
}
</script>
