<template>
  <div class="wrap">
    <section class="sub_visual myService_reg">
      <div class="sub-visual-cont">
        <h2>정기구독 서비스 만들기</h2>
        <span>2. 서비스 정보를 입력해주세요</span>
      </div>
    </section>
    <section class="container-limit reg-service">
      <v-row no-gutters justify="center">
        <v-col cols="12" md="8" lg="6">
          <div class="flow-map">
            <div class="d-flex flex-row justify-space-between">
              <div class="flow-item">
                <div class="ball">1</div>
                <span>사업자 정보입력</span>
              </div>
              <div class="flow-item">
                <div class="ball active">2</div>
                <span>서비스 정보입력</span>
              </div>
              <div class="flow-item">
                <div class="ball">3</div>
                <span>서비스 신청완료</span>
              </div>
            </div>
            <hr>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters justify="center">
        <v-col cols="12" sm="10" md="8" lg="6">
          <div class="reg-title-wrap">
            <h3>2. 서비스 정보입력</h3>
          </div>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="form.serviceName"
                label="서비스 명"
                hint="정기구독 서비스의 이름"
                :rules="serviceName"
                required
                prepend-inner-icon="mdi-store"
              ></v-text-field>

              <v-text-field
                v-model="form.serviceDomain"
                label="도메인 주소"
                hint="도메인 주소가 있으시면 입력 해주세요"
                prepend-inner-icon="mdi-web"
              ></v-text-field>
              <span class="info-text">도메인 주소가 없으시면 서비스명을 기초로 도메인 주소를 제작해 드립니다.</span>

              <div class="reg-info-box">
                <div class="icon-wrap">
                  <img src="/static/images/reg-icon1.png" alt="아이콘 이미지">
                </div>
                <div class="content">
                  <h4 class="reg-sub-title">서비스 로고</h4>
                  <span>
                    로고는 배경이 투명한 png파일로 업로드 해주세요 로고가 없으시면 서비스명으로 나타납니다.
                  </span>
                </div>
              </div>
              <v-file-input
                v-model="form.serviceLogoFile"
                accept="image/png"
                label="로고 이미지 업로드"
              ></v-file-input>

              <div class="reg-info-box">
                <div class="icon-wrap">
                  <img src="/static/images/reg-icon2.png" alt="아이콘 이미지">
                </div>
                <div class="content">
                  <h4 class="reg-sub-title">메인문구</h4>
                  <span>
                    메인화면의 타이틀 문구를 입력 해주세요
                  </span>
                </div>
              </div>
              <v-textarea
                v-model="form.mainTitle"
                rows="3"
                outlined
                placeholder="메인 타이틀 문구를 입력해주세요"
              ></v-textarea>

              <div class="reg-info-box">
                <div class="icon-wrap">
                  <img src="/static/images/reg-icon3.png" alt="아이콘 이미지">
                </div>
                <div class="content">
                  <h4 class="reg-sub-title">메인 배경</h4>
                  <span>
                    배경 이미지를 업로드 해주세요.
                  </span>
                </div>
              </div>
              <v-file-input
                v-model="form.mainBGFile"
                label="배경 이미지 업로드 (가로 1920px 사이즈 권장)"
              ></v-file-input>

              <div class="reg-info-box">
                <div class="icon-wrap">
                  <img src="/static/images/reg-icon4.png" alt="아이콘 이미지">
                </div>
                <div class="content">
                  <h4 class="reg-sub-title">브랜드 스토리</h4>
                  <span>
                    브랜드를 소개하는 제목과 내용을 입력 해주세요
                  </span>
                </div>
              </div>
              <v-text-field
                v-model="form.brandTitle"
                label="브랜드 스토리 타이틀"
                hint="브랜드 스토리 제목을 입력해주세요"
                prepend-inner-icon="mdi-lead-pencil"
              ></v-text-field>

              <v-textarea
                v-model="form.brandContents"
                rows="5"
                outlined
                placeholder="브랜드 스토리 내용을 입력해주세요"
                hide-details="true"
              ></v-textarea>

              <v-file-input
                v-model="form.brandImage"
                label="브랜드 이미지"
              ></v-file-input>
              <span class="info-text">브랜드 이미지가 있으시면 업로드 해주세요</span>

              <div class="reg-info-box">
                <div class="icon-wrap">
                  <img src="/static/images/reg-icon5.png" alt="아이콘 이미지">
                </div>
                <div class="content">
                  <h4 class="reg-sub-title">영상소스</h4>
                  <span>
                    제작된 유튜브 영상이 있으시면 링크를 적어 주세요
                  </span>
                </div>
              </div>
              <v-text-field
                v-model="form.movieUrl"
                label="영상링크 주소"
                hint="유튜브 링크를 입력해주세요"
                prepend-inner-icon="mdi-movie-outline"
              ></v-text-field>

              <div class="reg-info-box">
                <div class="content">
                  <h4 class="reg-sub-title">SNS 정보 </h4>
                  <span>
                    운영하고 계신 SNS서비스가 있으시면 알려 주세요
                  </span>
                </div>
              </div>
              <v-text-field
                v-model="form.facebookUrl"
                label="FACEBOOK 채널 주소"
                prepend-icon="mdi-facebook"
              ></v-text-field>
              <v-text-field
                v-model="form.instagramUrl"
                label="Instagram 채널 주소"
                prepend-icon="mdi-instagram"
              ></v-text-field>
              <v-text-field
                v-model="form.youtubeUrl"
                label="Youtube 채널 주소"
                prepend-icon="mdi-youtube"
              ></v-text-field>

              <div class="reg-info-box">
                <div class="icon-wrap">
                  <img src="/static/images/reg-icon6.png" alt="아이콘 이미지">
                </div>
                <div class="content">
                  <h4 class="reg-sub-title">서비스 장점</h4>
                  <span>
                    서비스 장점을 3가지로 소개 해주세요.
                  </span>
                </div>
              </div>
              <v-text-field
                v-model="form.advantageSubject"
                label="서비스 장점 타이틀"
                hint=""
              ></v-text-field>
              <v-card class="pt-2 pb-2 pl-4 pr-4 mb-4" elevation="0" outlined>
                <v-text-field
                  v-model="form.advantage1Title"
                  label="1번 장점 타이틀"
                ></v-text-field>
                <v-textarea
                  v-model="form.advantage1Contents"
                  rows="3"
                  outlined
                  hide-details="true"
                  placeholder="1번 장점에 대한 내용을 입력해주세요"
                ></v-textarea>
                <v-file-input
                  v-model="form.advantage1Image"
                  label="이미지 업로드"
                ></v-file-input>
              </v-card>
              <v-card class="pt-2 pb-2 pl-4 pr-4 mb-4" elevation="0" outlined>
                <v-text-field
                  v-model="form.advantage2Title"
                  label="2번 장점 타이틀"
                ></v-text-field>
                <v-textarea
                  v-model="form.advantage2Contents"
                  rows="3"
                  outlined
                  hide-details="true"
                  placeholder="2번 장점에 대한 내용을 입력해주세요"
                ></v-textarea>
                <v-file-input
                  v-model="form.advantage2Image"
                  label="이미지 업로드"
                ></v-file-input>
              </v-card>

              <v-card class="pt-2 pb-2 pl-4 pr-4 mb-4" elevation="0" outlined>
                <v-text-field
                  v-model="form.advantage3Title"
                  label="3번 장점 타이틀"
                ></v-text-field>
                <v-textarea
                  v-model="form.advantage3Contents"
                  rows="3"
                  outlined
                  hide-details="true"
                  placeholder="3번 장점에 대한 내용을 입력해주세요"
                ></v-textarea>
                <v-file-input
                  v-model="form.advantage3Image"
                  label="이미지 업로드"
                ></v-file-input>
              </v-card>

              <span class="reg-info">내용을 검토 하신 후 작성을 완료 하세요. </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" lg="4">
              <button class="btn-big btn-primary btn-block" @click="goRegisterComplete()">입력완료</button>
            </v-col>
            <v-col cols="5" lg="4">
              <button class="btn-big btn-block" @click="goBack()">취소</button>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

    </section>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'registerService',
  data() {
    return {
      userId: null,
      form: {
        companyId: null,
        serviceName: '',
        serviceDomain: '',
        serviceLogoFile: null,
        mainTitle: '',
        mainBGFile: null,
        brandTitle: '',
        brandContents: '',
        brandImage: null,
        movieUrl: '',
        facebookUrl: '',
        instagramUrl: '',
        youtubeUrl: '',
        advantageSubject: '',
        advantage1Title: '',
        advantage1Contents: '',
        advantage1Image: null,
        advantage2Title: '',
        advantage2Contents: '',
        advantage2Image: null,
        advantage3Title: '',
        advantage3Contents: '',
        advantage3Image: null
      },
      serviceName: [
        v => !!v || '서비스명은 필수 입니다.'
      ]
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      axios.get('/api/user/me').then(({ data }) => {
        console.log(data.result)
        this.userId = data.result.userId
        this.getCompanyInfo()
      }).catch((error) => {
        console.log(error)
      })
    },
    getCompanyInfo() {
      axios.get('/api/company/service/getCompanyByRegisterUserId/' + this.userId).then(({ data }) => {
        console.log(data.result)
        if (data.result) {
          this.form.companyId = data.result.companyId
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    goRegisterComplete() {
      const formData = new FormData()
      formData.append('companyId', this.form.companyId)
      formData.append('serviceName', this.form.serviceName)
      formData.append('serviceDomain', this.form.serviceDomain)
      formData.append('mainTitle', this.form.mainTitle)
      formData.append('brandTitle', this.form.brandTitle)
      formData.append('brandContents', this.form.brandContents)
      formData.append('movieUrl', this.form.movieUrl)
      formData.append('facebookUrl', this.form.facebookUrl)
      formData.append('instagramUrl', this.form.instagramUrl)
      formData.append('youtubeUrl', this.form.youtubeUrl)
      formData.append('advantageSubject', this.form.advantageSubject)
      formData.append('advantage1Title', this.form.advantage1Title)
      formData.append('advantage1Contents', this.form.advantage1Contents)
      formData.append('advantage2Title', this.form.advantage2Title)
      formData.append('advantage2Contents', this.form.advantage2Contents)
      formData.append('advantage3Title', this.form.advantage3Title)
      formData.append('advantage3Contents', this.form.advantage3Contents)

      if (this.form.serviceLogoFile) {
        formData.append('serviceLogoFile', this.form.serviceLogoFile)
      }
      if (this.form.mainBGFile) {
        formData.append('mainBGFile', this.form.mainBGFile)
      }
      if (this.form.brandImage) {
        formData.append('brandImage', this.form.brandImage)
      }
      if (this.form.advantage1Image) {
        formData.append('advantage1Image', this.form.advantage1Image)
      }
      if (this.form.advantage2Image) {
        formData.append('advantage2Image', this.form.advantage2Image)
      }
      if (this.form.advantage3Image) {
        formData.append('advantage3Image', this.form.advantage3Image)
      }
      axios.post('/api/company/service/registerCompanyConfig', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        console.log(response.data)
        this.$router.push('registerFinish')
      }).then(error => {
        console.log(error)
      })
    },
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>
