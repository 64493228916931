<template>
  <section class="join-wrap">
    <div class="hidden-box">
      <div class="inner-box">
        <div class="tit-wrap">
          <h3>ShopEx 회원가입</h3>
          <span>이메일 아이디와 비밀번호를 입력해주세요</span>
        </div>
        <v-text-field
          v-model="form.emailAddress"
          class="pb-2"
          label="E-mail ID"
          hint="이메일 주소를 입력해주세요"
          persistent-hint
        ></v-text-field>

        <v-text-field
          v-model="form.username"
          class="pb-2"
          label="이름"
          hint="이름을 입력해주세요."
          persistent-hint
        ></v-text-field>

        <v-text-field
          v-model="user_pw"
          class="pb-2"
          label="Password"
          hint="영문, 숫자포함 8자리 이상 입력해주세요"
          persistent-hint
          :rules="user_pw_rule"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show ? 'text' : 'password'"
          @click:append="show = !show"
        ></v-text-field>

        <v-text-field
          v-model="password_chk"
          class="pb-2"
          label="Password 확인"
          :rules="user_pw_rule2"
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show2 ? 'text' : 'password'"
          @click:append="show2 = !show2"
        ></v-text-field>
        <div class="pt-10 pb-10">
          <v-row>
            <v-col>
              <button class="btn-big btn-block" @click="goHome()">취소</button>
            </v-col>
            <v-col>
              <button class="btn-big btn-primary btn-block" @click="register()">가입하기</button>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'join2',
  data() {
    return {
      state: 'ins',
      form: {
        username: null,
        emailAddress: this.emailId,
        password: null
      },
      show: false,
      show2: false,
      emailId: '',
      emailRules: [
        v => !!v || '이메일 아이디를 입력해주세요',
        v => /.+@.+/.test(v) || '이메일 형식에 맞춰 주세요'
      ],
      user_pw: '',
      password_chk: '',
      user_pw_rule: [
        v => this.state === 'ins' ? !!v || '패스워드를 입력해주세요' : true,
        v => !(v && v.length >= 30) || '패스워드는 30자 이상 입력 하실 수 없습니다.'
      ],
      user_pw_rule2: [
        v => this.state === 'ins' ? !!v || '패스워드를 입력해주세요' : true,
        v => !(v && v.length >= 30) || '패스워드는 30자 이상 입력 하실 수 없습니다.',
        v => v === this.user_pw || '패스워드가 일치하지 않습니다.'
      ],
      oauthUser: null,
      errorMessage: null
    }
  },
  created() {
    this.oauthUser = this.oauth2User()
    this.initInfo()
  },
  methods: {
    ...mapGetters({
      oauth2User: 'userStore/oauth2User'
    }),
    initInfo() {
      if (this.oauthUser != null) {
        this.form.username = this.oauthUser.properties.nickname
        this.form.emailAddress = this.oauthUser.kakao_account.email
      }
    },
    goHome() {
      this.$router.push('/')
    },
    register() {
      this.form.password = this.password_chk
      console.log(this.form)
      axios.post('/api/company/user/register', this.form).then(({ data }) => {
        console.log(data)
        this.userId = data.userId
        this.$router.push({ name: 'join3', params: { info: this.form.emailAddress } })
      }).catch((error) => {
        alert(error.response.data.message)
        this.errorMessage = error.response.data.message
        console.log(error)
      })
    }
  }
}
</script>
