<template>
    <footer>
        <div class="container-limit">
            <div class="wrap-footer">
                <div class="foot-logo">
                    <img src="static/images/footer-logo.png" alt="Logo">
                </div>
                <div class="foot-content">
                    <div class="company-info">
                      <div class="">
                        <v-dialog
                          transition="dialog-bottom-transition"
                          v-model="termsDialog"
                          max-width="600"
                          scrollable>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              v-bind="attrs"
                              v-on="on"
                            >이용약관
                            </span>
                          </template>

                          <v-card>
                            <v-card-title class="modal-header">
                              [ShopEx 서비스 이용약관]
                            </v-card-title>

                            <v-card-text style="height:400px;">
                              <br/>
                              제1조 (목적)<br/><br/>
                              본 약관은 서비스를 제공하는 나인빌리언앤코 (이하 “회사”)과 회사의 서비스를 이용하는 온라인 상의 인터넷 서비스 (이하 “회원”)간에 서비스 이용에 관한 권리와 의무 및 책임 기타 제반 사항을 규정함을 목적으로 합니다.
                              <br/><br/>
                              제2조 (용어 정의)<br/><br/>
                              1. 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br/>
                              ① ‘회원’ 이라함은 이 약관에 동의하고 회원가입을 한 자로, 회사가 제공하는 서비스를 이용할 수 있는 개인, 사업자, 법인 등을 말합니다.<br/>
                              ② ‘사이트’ 이라 함은 ShopEx에서 제공 받은 쇼핑몰 호스팅, 사이트 호스팅 및 회원이 외부 호스팅을 이용하여 연결된 쇼핑몰, 사이트를 통칭합니다.<br/>
                              ③ ‘ShopEx’ 이라 함은 나인빌리언앤코에서 개발하여 제공하는  정기구독 사이트 제작하는 모든 서비스를 말합니다.<br/>
                              2. 이 약관에서 사용하는 용어 중 제 1항에서 정하지 아니한 것은 관계 법령 및 서비스 별 안내에서 정하는 바에 따르며, 그 외에는 일반 관례에 따릅니다.<br/><br/>

                              제3조 (회사의 역할과 의무)<br/><br/>
                              1. 회사는 본 약관이 정하는 바에 따라 지속적이고 안정적인 서비스를 제공하기 위해서 노력합니다.<br/>
                              2. 회사는 회원의 개인정보 보호를 위해 보안 시스템을 구축하며 개인정보 취급방침을 공시하고 준수합니다.<br/>
                              3. 회사는 공정하고 건전한 운영을 위해 최선을 다하고 지속적인 연구개발을 통하여 양질의 서비스를 제공하기 위해 노력합니다.<br/>
                              4. 회사는 회원으로부터 제기되는 의견이나 불편사항이 객관적으로 인정될 경우에는 적절한 절차를 통해 처리하여야 합니다. 다만, 신속한 처리가 곤란한 경우는 회원에게 그 사유와 예상 일정을 통보하여야 합니다.
                              <br/><br/>
                              제4조 (회원의 역할과 의무)<br/><br/>
                              1. 회원은 사이트의 관리 및 보안 의무가 있습니다.<br/>
                              2. 회원은 자신이 운영하는 사이트의 이용 고객 관리에 대한 개인정보 보호 의무가 있습니다.<br/>
                              3. 회원은 사이트를 운영하는 데 있어서 공공질서, 미풍양속, 기타 관계법령에 위배되는 상행위를 할 수 없으며, 일반적으로 통용되는 상도덕을 지켜야 할 의무가 있습니다.<br/>
                              4. 회원은 회사의 서비스 이미지를 손상시킬 수 있는 게시물 작성, 불량 상품 및 온라인 판매 금지 상품의 판매, 배송 지연, 환불 문제 등 부당한 상행위를 할 수 없습니다.<br/>
                              5. 회원은 위탁 운영자를 지정할 수 있으며, 위탁 운영자는 신의 성실의 원칙에 의거하여 사이트를 관리해야할 의무 및 책임이 있습니다.<br/>
                              6. 위탁 운영자에 대한 관리 및 책임은 전적으로 회원에게 있습니다.<br/>
                              위 조항을 위배할 경우 회사는 사전 경고 없이 해당 사이트를 중지 또는 폐쇄 할 수 있습니다.
                              <br/><br/>
                              제5조 (라이센스 규정)<br/><br/>
                              1. 회사에서 개발한 모든 기능의 저작권은 (주)나인빌리언앤코에게 있으며, 회원은 사용권을 제공 받습니다.<br/>
                              2. 라이센스는 1개의 도메인에서만 이용 가능하며, 다수의 도메인에서 이용할 수 없습니다.<br/>
                              3. 라이센스는 영구 라이센스, 기간제 라이센스, 충전형 라이센스로 구분되며, 개별 정책은 다음과 같습니다.<br/>
                              ① 영구 라이센스는 영구적으로 업데이트 및 기술지원을 제공 받습니다.<br/>
                              ② 기간제 라이센스는 구입 1년까지 업데이트, 기술지원, 설정 변경이 가능하며, 이후 플러그인 사용은 가능하나 업데이트, 기술지원, 설정 변경은 이용하실 수 없습니다. 이후 업데이트, 기술지원, 설정이 필요한 경우 라이센스 연장 또는 갱신을 통한 서비스 이용이 가능합니다.<br/>
                              ③ 충전형 라이센스는 이용권 소진이 된 경우 업데이트 및 기술지원이 제공되지 않습니다.<br/>
                              4. 라이센스는 상품에 따라 다르며, 상품 상세 페이지의 라이센스 정책에 준합니다.<br/>
                              5. 라이센스 상품에 대해 임의로 변경, 삭제, 도용할 수 없으며 회사의 사전 승낙 없이 제3자에게 제공하거나, 복제, 출판 등의 방법으로 배포할 수 없습니다.<br/>
                              이를 위반 시 민형사상의 책임 및 지적 재산권에 대한 손해 배상 의무를 갖습니다.<br/><br/>

                              제6조 (서비스 개시)<br/><br/>
                              1. 서비스 이용은 회사가 회원에게 주문 처리 완료에 따른 이메일 전송 시점부터 가능합니다.<br/>
                              2. 서비스 이용 시간은 회사의 업무 및 기술상 장애 등 특별한 사유가 없는 한 연중 무휴, 1일 24시간 이용할 수 있습니다. 단, 제 10조에 한하여 이용이 제한 될 수 있습니다.<br/><br/>

                              제7조 (서비스 이용 요금)<br/><br/>
                              1. ShopEx의 서비스는 무료이며, 추후 요금의 정책 변경 시 이메일을 통해 안내합니다.<br/>
                              2. 서비스 이용 요금은 회사의 정책에 의하여 변경될 수 있습니다.<br/><br/>

                              제8조 (서비스 해지 및 환불)<br/><br/>
                              1. 회사는 운영자가 업무 방해 행위를 하거나 규정에 없는 무리한 요구를 하는 경우, 즉시 해지 처리할 수 있습니다. 또한, 해당 운영자의 서비스 연장을 거부할 수 있습니다.<br/><br/>

                              제9조 (정기구독 서비스)<br/><br/>
                              1. 정기구독 서비스는 다음 갱신에 따른 결제일과 결제금액이 결제 페이지에서 고지되며, 결제 완료를 통해 정기구독 서비스에 동의한 것으로 간주합니다.<br/>
                              2. 정기구독 갱신 결제는 다음 결제일 7일전 회원의 이메일로 고지 안내 됩니다.<br/>
                              3. 정기구독에 따른 갱신 결제는 환불이 되지 않습니다.<br/>
                              4. 정기구독 서비스 해지를 원하는 경우 내계정 페이지에서 정기구독 해지를 진행할 수 있습니다.<br/><br/>

                              제10조 (서비스 이용 만료)<br/><br/>
                              1. 서비스 이용 만기일 이후 서비스 연장을 진행하지 않은 경우 사이트 운영 권한이 소멸되며, 사용 만기일 3일이 경과된 사이트는 소비자 피해 방지를 위하여 사이트의 임시 차단이 시행 됩니다.<br/>
                              2. 임시 차단 사이트는 차단일로 부터 3일이내 서비스 연장이 되지 않는 경우 영구적으로 차단 됩니다.<br/>
                              3. 서비스 이용 만기로 인해 영구적으로 차단된 사이트는 데이터가 삭제되며 삭제된 데이터는 복구할 수 없습니다.<br/>
                              4. 서비스 이용 만기에 따른 데이터 백업은 필요 시 회원이 진행하며, 회사는 이용이 종료된 사이트에 대한 데이터 백업을 진행하지 않습니다.<br/><br/>

                              제11조 (서비스 제공의 중지)<br/><br/>
                              1. 회사는 긴급 시스템 점검, 증설 및 교체, 설비의 장애, 서비스 이용의 폭주, 국가비상사태, 정전 등 부득이한 사유가 발생한 경우 사전 예고 없이 일시적으로 서비스의 전부 또는 일부를 중단할 수 있습니다.<br/>
                              2. 회사는 서비스 개편 등 서비스 운영 상 필요한 경우 서비스의 제공을 중단할 수 있으며 회원에게 사전 예고 또는 사후 통보 합니다.<br/>
                              3. 회사는 사이트의 해킹 관련 의심이 드는 경우 및 전체 시스템의 과부하 원인을 제공한다고 판단될 경우 사이트의 접속 제한을 할 수 있으며, 회원에게 사전 예고 또는 사후 통보 합니다.<br/>
                              4. 회사는 사이트 관리가 전혀 되지 않는다고 판단 된 경우 보안 관리를 위해 서비스 제공을 중지할 수 있으며, 사전 예고 또는 사후 통보 합니다.<br/>

                              제12조 (서비스 범위 및 한계)<br/><br/>
                              1. 사이트 운영은 회사가 제공하는 결제 시스템 사용을 원칙으로 하며, 이를 위반 시 사이트 운영과 관련된 모든 기술지원을 받을 수 없습니다.<br/>
                              2. 또한 타사의 결제 플러그인 사용 적발 시, 서비스는 자동 해지되며, 해지 통보 14일 후 사이트 운영이 차단됩니다.<br/>
                              3. 회사에서 진행한 프로모션, 이벤트 등의 무료 서비스는 이용 제한 및 이용 중단이 발생할 수 있으며, 이용 중단이 발생하는 경우 회사는 ShopEx 사이트에 공지합니다.<br/><br/>

                              제13조 (손해배상)<br/><br/>
                              1. 회사는 유료 서비스의 이용과 관련하여 발생하는 회원의 손해에 대하여 관계법령이 정하는 한도 내에서 배상책임을 집니다. 단, 회원의 과실로 발생한 손해에 대해서는 해당 책임이 면제됩니다.<br/>
                              2. 회사는 무료로 제공하는 서비스의 이용과 관련하여 발생하는 회원의 손해에 대하여 어떠한 배상책임도 지지 않습니다.<br/><br/>

                              제14조 (면책조항)<br/><br/>
                              1. 회원이 회사와 제휴된 외부 서비스를 이용하는 과정에서 외부 서비스 제공사의 일방적인 정책 변경에 따라 발생하는 회원의 손해에 대해서는 외부 서비스 제공사에 배상 책임이 있으며, 이에 대해 회사는 면책 됩니다.<br/>
                              2. 회사는 기간통신사업자의 서비스 중지, 천재지변, 전쟁 등 기타 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 회사는 면책 됩니다.<br/>
                              3. 회사는 시스템 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대해 면책 됩니다.<br/>
                              4. 회사는 회원이 서비스에 게재한 정보, 자료, 사실의 정확성, 신뢰성 등 그 내용에 관하여는 어떠한 책임을 부담하지 아니하고, 회원은 자기의 책임 아래 서비스를 이용하며, 서비스를 이용하여 게시 또는 전송한 자료 등에 관하여 손해 발생 및 자료의 삭제, 선택, 기타 서비스 이용과 관련하여 불이익이 발생하는 경우에 대한 책임은 회원에게 있습니다.<br/>
                              5. 회사가 제공하는 정보와 자료는 거래의 목적으로 이용될 수 없습니다. 따라서 본 서비스의 정보와 자료 등에 근거한 거래는 전적으로 회원 책임과 판단 아래 수행 해야하며, 이로 인해 기대 수익이 발생하지 않는 경우 회사는 책임을 부담하지 않습니다.<br/>
                              6. 회사는 회원이 서비스를 이용하여 기대하는 수익을 얻지 못하거나 상실한 것에 대하여 책임을 지지 않으며, 서비스를 이용하면서 얻은 손해에 대하여 책임을 지지 않습니다.<br/>
                              7. 회사는 회원의 게시물의 내용을 확인 또는 검토 할 의무가 없으며, 그 결과에 대한 책임을 지지 않습니다.<br/><br/>

                              제15조 (회원에 대한 통지 및 정보제공)<br/><br/>
                              1. 회사는 회원이 서비스 이용 중 필요하다고 판단되는 다양한 정보를 공지사항, 이메일, 문자 알림 등의 방법으로 회원에게 전달 할 수 있습니다. 다만, 회원은 언제든지 이메일, 문자 거부 등에 대해 수신 거절을 할 수 있습니다.<br/>
                              2. 회사는 불특정다수 회원에게 통지를 해야 할 경우 회사 홈페이지를 통해 7일 이상 게시함으로써 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래등과 관련하여 회원에게 중대한 영향을 미치는 사항에 대하여는 개별로 통지 할 수 있습니다.<br/>
                              3. 회사는 서비스 개선 및 회원의 서비스 소개 등의 목적으로 회원의 동의 하에 관련 법령에 따라 추가적인 개인 정보를 수집할 수 있습니다.<br/><br/>

                              제16조 (게시물 관리)<br/><br/>
                              1. 회원은 공개된 게시물을 작성함에 있어, 보안상 문제가 될 수 있는 사이트 주요 정보 및 개인정보를 공개적으로 게시해서는 안되며, 게시 시 보안상 문제로 인한 책임은 게시물을 작성한 회원에게 있습니다.<br/>
                              2. 회원의 게시물이 ‘정보통신 이용촉진 및 정보보호 등에 관한 법률’ 및 ‘저작권’ 등 관계 법령에 위반되는 내용을 포함하는 경우, 권리자는 관계법령이 정한 절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며, 회사는 관계 법령에 따라 조치를 취해야 합니다.<br/>
                              3. 회사는 전항에 따른 권리자의 요청이 없는 경우라도 권리 침해가 인정될 만한 사유가 있거나 기타 회사정책 및 관련법에 위반되는 경우 해당 게시물에 대해 임시조치를 취할 수 있습니다.<br/><br/>



                              [ShopEx 회원 약관]<br/><br/>

                              제1조 (회원가입)<br/><br/>
                              1. 회원가입은 이용자가 서비스를 이용하기 위하여 이용 약관 및 회사의 개인정보취급방침에 동의한다는 의사 표시 후 이메일 인증을 통해 회원가입 신청을 완료 됩니다.<br/>
                              2. 회원으로 가입하여 서비스를 이용하고자 하는 경우 회원은 회사에서 요청하는 제반 정보를 제공해야 합니다.<br/><br/>

                              제2조 (회원가입 신청에 대한 승인 및 거부)<br/><br/>
                              1. 회사는 제 4조 3항에 의한 회원가입신청에 대하여 특별한 이유가 없는 한 이를 승인 함을 원칙으로 합니다.<br/>
                              2. 회사는 다음 호에 해당하는 이용자에 대하여 회원 가입을 승낙하지 않을 수 있고, 그 사유가 해소 될 때까지 승인을 유보할 수 있습니다.<br/>
                              ① 타인의 개인정보를 도용하여 회원가입을 신청한 경우<br/>
                              ② 회원가입 시 등록한 내용에 대해 허위, 기재누락, 오기가 있는 경우<br/>
                              ③ 회원가입을 신청한 이용자가 기존 이용약관에 의거하여 이전에 회원 자격을 상실한 적이 있는 경우<br/>
                              ④ 회사의 영업 및 기술상 장애 사유가 있는 경우<br/>
                              ⑤ 기타 회사가 필요하다고 객관적으로 인정할 수 있는 경우<br/><br/>

                              제3조 (회원의 의무)<br/><br/>
                              1. 회원은 반드시 회원 본인의 정보를 제공해야만 서비스를 이용할 수 있으며, 타인의 정보를 도용하거나 허위의 정보를 등록하는 등 본인의 정확한 정보를 등록하지 않은 회원은 서비스 이용과 관련하여 아무런 권리를 주장할 수 없으며, 회사 및 제 3자에게 야기한 손해에 대해 배상할 책임을 지고, 관계 법령에 따라 처벌 받을 수 있습니다.<br/>
                              2. 회원은 본 약관에서 규정하는 사항과 기타 회사가 정한 제반 규정, 공지사항 및 관계 법령을 준수하여야 하며, 기타 회사의 업무에 방해가 되는 행위, 회사의 명예를 손상시키는 행위, 타인에게 피해를 주는 행위를 해서는 안됩니다.<br/>
                              3. 회원은 주소, 연락처, 이메일 등 이용 정보가 변경된 경우 ShopEx 사이트를 통해 자신의 개인정보를 직접 수정 변경할 수 있으며, 필요 시 기술지원, 이메일 등을 이용하여 회원정보 변경을 요청할 수 있습니다.<br/>
                              4. 회원은 회원ID, 비밀번호 등이 타인에게 노출되지 않도록 보안 관리를 해야합니다.<br/>
                              5. 회사는 상기 1항, 2항, 3항, 4항을 위반하여 회원에게 발생한 손해에 대하여 어떠한 책임도 부담하지 않습니다.<br/>
                              6. 회사는 필요한 경우 허위 정보를 기재한 회원의 서비스 이용 자격을 제한 할 수 있습니다.<br/><br/>

                              제4조 (회원 탈퇴 및 자격 상실)<br/><br/>
                              1. 회원은 회사에 회원 탈퇴를 요청할 수 있으며 회사는 요청을 받은 즉시 회원 탈퇴를 위한 절차를 진행하며, 개인정보 취급방침에 따라 회원 정보를 삭제 합니다.<br/>
                              2. 회사의 서비스의 기간이 남아있는 회원이 탈퇴 요청 하였을 경우 회원탈퇴로 인한 서비스 중지 또는 피해에 대한 책임은 탈퇴 회원에게 있습니다.<br/>
                              3. 회원이 서비스 이용에 있어서 본 약관 제 2조 내용을 위반하거나, 다음 각 호의 사유에 해당하는 경우 회사는 직권으로 회원자격 상실 및 회원탈퇴의 조치를 할 수 있습니다.<br/>
                              ① 다른 사람의 명의를 사용하여 가입 신청한 경우<br/>
                              ② 신청 시 필수 작성 사항을 허위로 기재한 경우<br/>
                              ③ 회사가 제공하는 서비스의 이용과 관련하여 회원이 부담하는 의무 및 채무를 이행하지 아니한 경우<br/>
                              ④ 회사가 제공하는 서비스의 이용과 관련하여 타인의 명예를 손상 시키거나 불이익을 주는 행위를 한 경우<br/>
                              ⑤ 타인의 회사 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자거래질서를 위협하는 경우<br/>
                              ⑥ 회사가 제공하는 범위 이외에 부당한 서비스 요구, 업무에 방해되는 행외, 욕설, 비방을 하는 경우<br/>
                              ⑦ 관계 법령 위배와 본 약관이 금지하는 행위를 한 경우<br/>
                              4. 위 항목으로 인해 회사가 직권으로 회원탈퇴 처리를 하고자 하는 경우, 회원에게 소명의 기회를 부여합니다.<br/><br/>

                              제5조 (이용약관의 효력 및 개정)<br/><br/>
                              1. 이 약관은 ShopEx을 통해 온라인으로 공지하고 회원의 동의로 효력을 발생합니다. 본 약관의 공지는 회사 홈페이지(https://www.shopex.co.kr)에 게시하는 방법으로 합니다.<br/>
                              2. 회사는 합리적인 사유가 발생할 경우 관련 법령에 위배되지 않는 범위 내에서 약관을 개정할 수 있습니다. 개정된 약관은 회사 홈페이지(https://www.shopex.co.kr)를 통해 공지함으로 효력을 발휘합니다.<br/>
                              3. 회원은 정기적으로 회사의 홈페이지(https://www.shopex.co.kr)를 방문하여 약관의 변경사항을 확인하여야 합니다. 변경된 약관에 대한 정보를 알지 못해 발생하는 회원의 피해는 회사에서 책임지지 않습니다.<br/>
                              4. 회원은 변경된 약관에 동의하지 않을 경우 서비스 이용을 중단하고 회원 탈퇴를 요청할 수 있습니다. 단, 이의가 있음에도 불구하고 전 2항에 공지한 바에 따라 고지가 있은 후 30일이내에 이용 계약을 해지 않은 회원은 변경된 약관에 동의하는 것으로 간주합니다.<br/>
                              <br/>
                              제6조 (약관 외 준칙)<br/><br/>
                              회사는 필요한 경우 유무료 서비스 내의 운영원칙 또는 개별약관(이하 서비스 별 약관)을 정할 수 있으며, 본 약관과 서비스 개별 약관의 내용이 상충되는 경우에는 서비스 개별 약관의 내용을 우선하여 적용합니다.<br/><br/>

                              제7조 (약관의 해석)<br/><br/>
                              1. 본 약관에 명시되지 않은 사항은 관계 법령과 상관례에 따릅니다.<br/>
                              2. 회사의 개별 유무료 서비스 이용 회원의 경우 개별 서비스와 관련하여서는 회사가 별도로 정한 약관 및 정책에 따릅니다.<br/>

                              제8조 (재판관할 및 준거법)<br/><br/>
                              이 약관 및 서비스의 이용과 관련된 분쟁에 관한 소송은 회사의 본사 소재지를 관할하는 법원에 제기합니다.<br/>

                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="primary"
                                text
                                @click="termsDialog = false"
                              >
                                확인
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                        ㅣ
                        <v-dialog
                          v-model="infoDialog"
                          max-width="600"
                          transition="dialog-bottom-transition"
                          scrollable>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              v-bind="attrs"
                              v-on="on"
                            >개인정보취급방침
                            </span>
                          </template>

                          <v-card>
                            <v-card-title class="modal-header">
                              개인정보취급방침
                            </v-card-title>

                            <v-card-text style="height:400px;">
                              <br/>
                              [개인정보 수집이용 목적]<br/><br/>
                              회사의 개인정보 수집 목적은 온라인 쇼핑몰 사업자 및 사이트 운영자에게 최적화된 서비스를 제공하기 위함이며, 회사는 다음 항목 이용 목적 이외에 다른 용도로 이를 이용하거나 회원의 동의 없이 제 3자에게 이를 제공하지 않습니다.<br/>
                              1. 회원 관리를 위한 본인 확인, 개인 식별, 불량 회원의 부정 이용 방지<br/>
                              2. 최적화된 맞춤화 서비스를 위한 개발<br/>
                              3. 서비스 개발과 관련한 서비스 품목, 접속 빈도, 광고 개제 등에 따른 통계<br/>
                              4. 서비스 기능 업데이트 및 신규 기능에 대한 정보 전달<br/><br/>

                              [개인정보 수집항목]<br/><br/>
                              회사는 회원가입, 쇼핑몰 이용 및 결제, 결제 서비스 신청 및 이용 등을 위해 다음과 같은 개인정보를 수집하고 있습니다.<br/>
                              1. 회원 필수 항목 : 회원가입 이메일, 결제 청구자 이름, 이메일, 연락처<br/>
                              2. 결제 대행사 가입 신청 : 대표자 이름, 생년월일, 이메일, 연락처 / 관리자 이름, 이메일, 연락처<br/>
                              3. 부가항목 : 사이트 운영자 및 위탁 운영자의 이름, 이메일 연락처<br/><br/>

                              [개인정보 보유 및 이용기간]<br/><br/>
                              회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 개인정보를 지체 없이 파기합니다.<br/>
                              단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안 이용자 개인정보를 보관합니다.<br/>
                              1. 회사의 정보 보유<br/>
                              ① 보존이유: 불량 이용자의 재가입방지 및 명예훼손 등 권리침해 분쟁 및 수사협조<br/>
                              ② 보존기간: 서비스 해지일로부터 1년<br/>
                              2. 상법 등 법령에 따라 보존 할 필요가 있는 경우<br/>
                              ① 계약 또는 서비스 청약 철회 등에 관한 기록<br/>
                              보존근거: 전자상거래 등에서의 소비자보호에 관한 법률<br/>
                              보존기간: 5년<br/>
                              ② 대금결제 및 재화 등의 공급에 관한 기록<br/>
                              보존근거: 전자상거래 등에서의 소비자보호에 관한 법률<br/>
                              보존기간: 5년<br/>
                              ③ 본인 확인에 관한 기록<br/>
                              보존근거: 정보통신망 이용촉진 및 정보보호에 관한 법률 제44조의5 및 시행령 제29조<br/>
                              보존기간: 6개월<br/>
                              ④ 접속에 관한 기록보존<br/>
                              보존근거: 통신비밀보호법 제15조의2 및 시행령 제41조<br/>
                              보존기간: 3개월<br/>
                              3. 기타, 이용자의 개별적인 동의가 있는 경우에는 개별 동의에 따른 기간까지 보관합니다.<br/><br/>

                              [개인정보 삭제]<br/><br/>
                              개인정보 삭제를 원하시는 경우, 내계정 페이지에서 회원탈퇴를 신청하면 모든 개인정보가 삭제되며, 삭제 후 ShopEx에서 이용하신 구매내역 및 기술지원 등의 과거 데이터를 확인하실 수 없으니 주의 해 주시기 바랍니다.<br/>


                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="primary"
                                text
                                @click="infoDialog = false"
                              >
                                확인
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </div>
                        <span>
                        서울특별시 성동구 성수일로 1길1 7F 나인빌리언앤코 ㅣ CS CENTER : 070-8656-9610<br>Contact us : contact@shopex.com
                        </span>
                        <span>Copyright ⓒ 나인빌리언앤코</span>
                    </div>

                    <ul class="sns-info">
                        <li><a href="#"><img src="static/images/icon-insta.png" alt="Instagram"></a></li>
                        <li><a href="#"><img src="static/images/icon-youtube.png" alt="Youtube"></a></li>
                        <li><a href="#"><img src="static/images/icon-facebook.png" alt="Facebook"></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      termsDialog: false,
      infoDialog: false
    }
  }
}
</script>
