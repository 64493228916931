<template>
  <section class="login-wrap">
    <div class="hidden-box">
      <div class="inner-box">
        <div class="login-tit">
          <h3 class="big-tit">비밀번호 변경</h3>
          <span>
                        변경하실 새로운 비밀번호를 입력해 주세요<br>비밀번호는 영문과 숫자를 조합 하셔야 합니다.
                    </span>
        </div>
        <div class="pb-2">
          <v-text-field
            v-model="password"
            label="Password"
            :rules="passwordRules"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            @click:append="show = !show"
          ></v-text-field>
        </div>
        <div class="pb-8">
          <v-text-field
            v-model="password_chk"
            label="Password 확인"
            :rules="passwordRules2"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show2 ? 'text' : 'password'"
            @click:append="show2 = !show2"
          ></v-text-field>
        </div>
        <div>
          <button class="btn-big btn-primary btn-block" @click="submitForm()">변경 완료</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'

export default {
  name: 'changePW',
  data () {
    return {
      resetKey: this.$route.query.key || null,
      show: false,
      show2: false,
      state: 'ins',
      password: '',
      password_chk: '',
      passwordRules: [
        v => this.state === 'ins' ? !!v || '패스워드를 입력해주세요' : true,
        v => !(v && v.length >= 30) || '패스워드는 30자 이상 입력 하실 수 없습니다.'
      ],
      passwordRules2: [
        v => this.state === 'ins' ? !!v || '패스워드를 입력해주세요' : true,
        v => !(v && v.length >= 30) || '패스워드는 30자 이상 입력 하실 수 없습니다.',
        v => v === this.password || '패스워드가 일치하지 않습니다.'
      ]
    }
  },
  methods: {
    submitForm() {
      const sForm = new FormData()
      sForm.append('password', this.password)
      sForm.append('resetKey', this.resetKey)

      axios.post('/api/resetPwProcess', sForm).then(({ data }) => {
        console.log(data.result)
        alert('비밀번호가 변경되었습니다.')
        this.$router.push('/')
      }).catch((error) => {
        console.log(error)
        alert(error.response.data.message)
      })
    },
    back() {
      this.$router.push('/')
    }
  }
}
</script>
