<template>
  <section class="join-wrap">
    <div class="hidden-box">
      <div class="inner-box">
        <div class="tit-wrap">
          <h3>회원가입 완료</h3>
          <span>반갑습니다! 회원님!<br>로그인을 하신 후 서비스를 이용해주세요
                    </span>
        </div>
        <div class="pt-6">
          <button class="btn-big btn-red btn-block" @click="goLogin()">로그인으로 이동</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'join3',
  methods: {
    goLogin() {
      this.$router.push('/login')
    }
  }
}
</script>
