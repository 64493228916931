<template>
  <div class="wrap">
    <section class="sub_visual myService_reg">
      <div class="sub-visual-cont">
        <h2>정기구독 서비스 만들기</h2>
        <span>서비스 신청이 완료 되었습니다</span>
      </div>
    </section>
    <section class="container-limit reg-service">
      <v-row no-gutters justify="center">
        <v-col cols="12" md="8" lg="6">
          <div class="flow-map">
            <div class="d-flex flex-row justify-space-between">
              <div class="flow-item">
                <div class="ball">1</div>
                <span>사업자 정보입력</span>
              </div>
              <div class="flow-item">
                <div class="ball">2</div>
                <span>서비스 정보입력</span>
              </div>
              <div class="flow-item">
                <div class="ball active">3</div>
                <span>서비스 신청완료</span>
              </div>
            </div>
            <hr>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters justify="center">
        <v-col cols="12" sm="10" md="8" lg="6">
          <div class="reg-finish">
            <div class="img-wrap">
              <img src="static/images/img-party.png" alt="Party Image">
            </div>
            <h3>서비스 신청 완료</h3>
            <p class="c-text">
              정기구독 서비스 신청이 완료 되었습니다.<br>
              등록하신 정보로 서비스가 생성되면 이메일을 통해 안내 해드립니다<br>
              구독상품 등록은 서비스 개설 후 <strong>'내 서비스' > '스토어 관리하기'</strong>를 통해 등록 하실 수 있습니다.
            </p>
            <p class="c-sub-text">서비스 생성 기준은 영업일 기준 최대 0일 입니다.</p>
            <button class="btn-big btn-primary" @click="goHome()">확인</button>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
export default {
  name: 'registerFinish',
  methods: {
    goHome () {
      this.$router.push('/')
    }
  }
}
</script>
