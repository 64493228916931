<template>
  <header>
    <div id="navbar" class="nav-wrap hidden-sm-and-down">
      <nav class="navbar">
        <div class="logo">
          <a href="/"><img src="/static/images/logo.png" alt="Logo Image"></a>
        </div>
        <v-list class="nav-menu">
          <v-list-item
            v-for="item in items"
            :key="item.title"
            :to="item.link"
          >
            {{ item.title }}
          </v-list-item>
        </v-list>
        <div class="my-info">
          <!-- 로그인 후 -->
          <div v-if="isLogin">
            <span>{{emailAddress}}</span>
            <button class="logout" @click="logoutProcess()">Logout</button>
          </div>
          <div v-else>
            <a href="/login" class="login">
              <v-icon>mdi-lock-outline</v-icon>
              Login
            </a>
          </div>
        </div>
      </nav>
    </div>
    <div class="mobile-navbar hidden-md-and-up d-flex">
      <div class="m-logo">
        <a href="/"><img src="/static/images/logo.png" alt="Logo Image"></a>
      </div>
      <v-spacer></v-spacer>
      <v-btn class="mt-3" elevation="0" @click="drawer = !drawer">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-navigation-drawer v-model="drawer" absolute class="hidden-md-and-up">
        <v-list-item class="m-navi-header">
          <v-list-item-content>
            <v-list-item-title>
              ShopEx
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list nav class="m-navi-menu">
          <v-list-item
            v-for="item in items"
            :key="item.title"
            :to="item.link"
          >
            <v-list-item-icon class="pr-3">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <div class="my-info">
          <span>robin@9billion.care</span>
          <a href="#" class="logout">Logout</a>
        </div>
      </v-navigation-drawer>
    </div>
  </header>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Navi',
  data() {
    return {
      drawer: false,
      items: [
        { title: '주요기능', icon: 'mdi-view-dashboard', link: '/features' },
        { title: '내 서비스', icon: 'mdi-monitor', link: '/myService' },
        { title: '요금제', icon: 'mdi-credit-card', link: '/priceTable' },
        { title: '고객센터', icon: 'mdi-information-outline', link: '/csCenter' }
      ],
      emailAddress: null
    }
  },
  computed: {
    ...mapGetters({
      isLogin: 'userStore/isLogin'
    })
  },
  created() {
    if (this.isLogin) {
      this.getInfo()
    }
  },
  methods: {
    ...mapActions({
      logout: 'userStore/logout'
    }),
    getInfo() {
      axios.get('/api/user/me').then(({ data }) => {
        this.emailAddress = data.result.emailAddress
      }).catch((error) => {
        console.log(error)
      })
    },
    logoutProcess() {
      this.logout()
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
.m-navi-header {
  background: #194278;
  padding: 6px 16px;
}

.m-navi-header .v-list-item__title {
  color: #fff;
  font-weight: 700;
  font-size: 18px;
}

.m-navi-menu {
  color: #194278;
}
</style>
