import Vue from 'vue'
import Vuex from 'vuex'
import userStore from '@/store/module/userStore'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'

Vue.use(Vuex)

const ls = new SecureLS({ isCompression: true })

export default new Vuex.Store({
  modules: {
    userStore: userStore
  },
  plugins: [createPersistedState({
    paths: [],
    storage: {
      getItem: (key) => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: (key) => ls.remove(key)
    }
  })]
})
