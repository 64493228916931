<template>
  <v-app id="app">
    <navi v-if="url"></navi>
    <router-view/>
    <Footer v-if="url"></Footer>
  </v-app>
</template>
<script>
import Navi from '@/components/Navi'
import Footer from '@/components/Footer'

export default {
  name: 'App',
  components: {
    Navi, Footer
  },
  data() {
    return {
      url: true
    }
  },
  mounted() {
    const KAKAO_JS_ID = '17a04a0a579e277463b75c5e02219cbc'
    // eslint-disable-next-line no-undef
    Kakao.init(KAKAO_JS_ID)
  },
  created() {
    console.log(process.env)
    this.isEvent()
  },
  methods: {
    isEvent() {
      console.log(location.href.indexOf('lecelle'))

      if (location.href.indexOf('lecelle') > 0) {
        this.url = false
      }
    }
  }
}
</script>

<style>
[v-cloak] {
  display: none
}
</style>
